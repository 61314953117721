import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import AddMember from '../clients/addMember';
import swal from 'sweetalert2';
import Select from 'react-select';
import Dropzone from '../global/dropzone';
import EmailPolicy from './emailPolicy';
import { getClients, getMainClients, getMainClientsByAgentID } from '../api/clientAPI';
import {
  addPolicy,
  getInsuranceCompanies,  
  getProducts,
  postMotorPolicy,
  postMarinePolicy,
  addPolicyDocuments,
  getInsuranceCompaniesByAgentID,
  getProductsByAgentID} from '../api/policyAPI';
import { uploadFile, uploadFileList } from '../api/fileAPI';
import AddCompany from './addCompany';
import PolicyPaymentModal from './policyPaymentModal';
import AddDocument from './addDocument';
import { extractData } from './helperFunctions';
import { getAuthState } from '../../assets/scripts/login-util';
import moment from 'moment';
import PolicyCommissionModal from './PolicyCommissionModal';

const AddPolicy = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);
    const [businessType,        setBusinessType]        = useState('New');
    const [clients,             setClients]             = useState([]);
    const [selectedClient,      setSelectedClient]      = useState(null);
    const [addedClientId,       setAddedClientId]       = useState(null);
    const [addedMemberId,       setAddedMemberId]       = useState(null);
    const [file,                setFile]                = useState(null);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null);
    const [isMotorPolicy,       setIsMotorPolicy]       = useState(false);
    const [isMarinePolicy,      setIsMarinePolicy]      = useState(false);
    const [addModalType,        setAddModalType]        = useState('Company');
    const [policyDetails,       setPolicyDetails]       = useState({'NCB' : 0, 'TPPremium': 0, 'ODPremium': 0, 'AddOnPremium' : 0, 'SendReminder' : 'Y', GST: 18});
    const [documents,           setDocuments]           = useState([]);
    const [addDocument,         setAddDocument]         = useState(false);
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [emailPolicyId,       setEmailPolicyId]       = useState(null);
    const [policySaved,         setPolicySaved]         = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState('');
    const [showRenewalBtn,      setShowRenewalBtn]      = useState(false);
    const [lastUpdatedField,    setLastUpdatedField]    = useState(null);
    const [paymentDetails,      setPaymentDetails]      = useState({ PaymentType: 0 });
    const [showPaymentModal,    setShowPaymentModal]    = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
        $('#add-policy-modal').modal({
          backdrop: 'static',
        });
        $('#add-policy-modal').on('hidden.bs.modal', function () {
          props.onDismissModal('policy', false);
          if (props.from === 'searchPolicy') $('#search-policy-modal').removeClass('modal-blur');
        });
        $('#add-policy-modal').modal('toggle');
        if (props.from === 'searchPolicy') $('#search-policy-modal').addClass('modal-blur');  
       
         fetchClients();  
         fetchInsuranceCompanies();
         fetchProducts();
    }, [props.show]);

    useEffect(() => {
      $('[data-toggle="kt-popover"]').popover();
    })

    useEffect(() => {

      if (clients.length > 0) {

        if (addedClientId){
          const client = clients.find(c => c.ID == addedClientId);
          setSelectedClient(client);
          setAddedClientId(null);
        }             

        if (addedMemberId){
          const client = clients.find(c => c.ID == addedMemberId);
          setSelectedClient(client);
          setAddedMemberId(null);
        }       
      }  

    }, [clients]);

  useEffect(() => {
    if (lastUpdatedField === "GST" && policyDetails.Premium) {
      const calculatedGrossPremium = Math.ceil(
        policyDetails.Premium + (policyDetails.Premium * policyDetails.GST) / 100
      );

      if (calculatedGrossPremium !== policyDetails.GrossPremium) {
        setPolicyDetails((prev) => ({
          ...prev,
          GrossPremium: calculatedGrossPremium,
        }));
      }
    }
  }, [policyDetails.Premium, policyDetails.GST, lastUpdatedField]);

  useEffect(() => {
    if (lastUpdatedField === "Premium") {
      const calculatedGrossPremium = Math.ceil(
        policyDetails.Premium + (policyDetails.Premium * policyDetails.GST) / 100
      );

      if (calculatedGrossPremium !== policyDetails.GrossPremium) {
        setPolicyDetails((prev) => ({
          ...prev,
          GrossPremium: calculatedGrossPremium,
        }));
      }
    }
  }, [policyDetails.Premium])

    // Update GST when GrossPremium is updated
  useEffect(() => {
    if (lastUpdatedField === "GrossPremium" && policyDetails.Premium) {
      const calculatedGST = ((policyDetails.GrossPremium - policyDetails.Premium) / policyDetails.Premium) * 100;

      if (Number(policyDetails.GST) !== Number(parseInt(calculatedGST))) {
        setPolicyDetails((prev) => ({
          ...prev,
          GST: calculatedGST,
        }));
      }
    }
  }, [policyDetails.Premium, policyDetails.GrossPremium, lastUpdatedField]);

  useEffect(() => {
    if (isMotorPolicy && (policyDetails.TPPremium || policyDetails.ODPremium || policyDetails.AddOnPremium)) {
      setPolicyDetails((prev) => ({
        ...prev,
        Premium: (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
        : ((policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? parseInt(policyDetails.TPPremium) + parseInt(policyDetails.AddOnPremium) : parseInt(policyDetails.TPPremium),
      }));
      setLastUpdatedField('Premium')
    }
  }, [policyDetails.TPPremium, policyDetails.ODPremium, policyDetails.AddOnPremium])

    useEffect(() => {   
        if (addedClientId || addedMemberId)
            fetchClients();        
    }, [addedClientId, addedMemberId]);

    useEffect(() => {       
        if (addedCompanyId)
            fetchInsuranceCompanies();  
    }, [addedCompanyId]);

    useEffect(() => {  
        if (addedProductId)
            fetchProducts();        
    }, [addedProductId]);

    useEffect(() => {    
        if (props.policyData && props.policyData.addedClientId)
            setAddedClientId(props.policyData.addedClientId);

        if (props.policyData && props.policyData.batchPolicy)
            setBusinessType('Renewal');  
    }, [props.policyData]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
          updatePolicyDetails('InsuranceCompanyID', addedCompanyId);
          setAddedCompanyId(null);
        }          
    }, [insuranceCompanies]);

    useEffect(() => {  
        if (products.length > 0 && addedProductId) {
          updatePolicyDetails('ProductID', addedProductId);
          setAddedProductId(null);
        }    
    }, [products]);

    useEffect(() => {  
        if (policyDetails.ProductID) {
          const product = products.find((p) => p.ID == policyDetails.ProductID);
          if (product.ExtraFields === "MotorPolicy") {
            setIsMotorPolicy(true);
            setIsMarinePolicy(false);
            if (!policyDetails.Type){
              updatePolicyDetails('Type', "Petrol");
            }            
          }else if (product.ExtraFields === "MarinePolicy") {
            setIsMotorPolicy(false);
            setIsMarinePolicy(true);
            if (!policyDetails.DeclarationBasis){
              updatePolicyDetails('DeclarationBasis', "Monthly");
            } 
          }
          else{
            setIsMotorPolicy(false);
            setIsMarinePolicy(false);
          }
        }

        if (policyDetails.FromDate && policyDetails.ToDate) {
          const diff = moment(policyDetails.ToDate).diff(policyDetails.FromDate, 'days')
          if (diff < 362) {
            setShowRenewalBtn(true)
          } else {
            setShowRenewalBtn(false)
          }
        }
    }, [policyDetails]);

    useEffect(() => {
      if (policySaved) {
          $('#add-policy-modal').modal('hide');
          props.onDismissModal('policy', false, '', policySaved);
      }
  }, [policySaved]);

  const refetchPolicies = () => setPolicySaved(true);

    const fetchClients = async () => {
      try {
        const response = userDetails.user.categoryId != 3 ? await getMainClients() : await getMainClientsByAgentID();
        setClients(
          response.map((c) => ({
            ...c,
            DisplayName:
              c.Type === 'Corporate' && c.Category === 'Primary'
                ? c.CompanyName
                : `${c.FirstName} ${c.MiddleName} ${c.LastName}`
          }))
          .sort((a, b) =>
            a.DisplayName > b.DisplayName
              ? 1
              : a.DisplayName < b.DisplayName
              ? -1
              : 0
          )
        );  
      } catch (err) {
        swal.fire({
            icon              : 'error',
            titleText         : 'Error!',
            text              : err.message,
            buttonsStyling    : false,
            confirmButtonClass: 'btn btn-brand',
        });
      }   
    };

    const fetchInsuranceCompanies = async () => {
      try {
        const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
        setInsuranceCompanies(
          response
          .sort((a, b) =>
            a.Name > b.Name
              ? 1
              : a.Name < b.Name
              ? -1
              : 0
          )
        );     
      } catch (err) {
        swal.fire({
            icon              : 'error',
            titleText         : 'Error!',
            text              : err.message,
            buttonsStyling    : false,
            confirmButtonClass: 'btn btn-brand',
        });
      }
    };

    const fetchProducts = async () => {
      try {
        const response = userDetails.user.categoryId != 3 ? await getProducts() : await getProductsByAgentID();
        setProducts(
          response
          .sort((a, b) =>
            a.Name > b.Name
              ? 1
              : a.Name < b.Name
              ? -1
              : 0
          )
        );     
      } catch (err) {
        swal.fire({
            icon              : 'error',
            titleText         : 'Error!',
            text              : err.message,
            buttonsStyling    : false,
            confirmButtonClass: 'btn btn-brand',
        });
      }
    };
    const updatePolicyDetails = (field, value) => {
      if (field === 'GST' || field === 'GrossPremium' || field === 'Premium') {
        setPolicyDetails((prev) => ({
          ...prev,
          [field]: Number(value) || 0,
        }));
        setLastUpdatedField(field);
      } else {
        setPolicyDetails((prev) => ({
          ...prev,
          [field]: value,
        }));
      }
    };

    const setEditorData = (html) => {
      updatePolicyDetails("AdditionalData", html) ;
    };

    const memberAddHandler = (e) => {
        e.preventDefault();
        $('#add-policy-modal').addClass('modal-blur');
        $('#add-member-modal').modal('toggle');
    };

    const clientAddHandler = (e) => {
        e.preventDefault();
        $('#add-policy-modal').addClass('modal-blur');
        props.onOpenModal('client', true, 'policy', '', '', {type: 'directClient'});
    };

    const getClientName = () => {
        const foundClient = clients.find((c) => c.GroupID === selectedClient.GroupID && c.Category === 'Primary');
        if (foundClient)
          return { DisplayName: foundClient.DisplayName,  Type: foundClient.Type};
        else return { DisplayName: '',  Type: ''};
    };

    const fileHandler = async (f) => {
        setFile(f);
        if (f.type === 'application/pdf') {
          try {
            swal.fire({
              titleText           : 'Extracting Data',
              text                : 'Please Wait...',
              showConfirmButton   : false,
              onOpen: () => {
                  swal.showLoading();
              }
            });
            const data = await extractData(f);
            if (data) {
              setPolicyDetails((d) => {
                return { ...d, ...data };
              });
            }
            await swal.close();
          } catch (err) {
              await swal.close();
              console.error(err);
          }
        }
    };  

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
        setIsMotorPolicy(false)
        setPolicyDetails({'NCB' : 0});
    }

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');   
        $('#add-policy-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };
    
    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#add-policy-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const setDocumentHandler = (d) => {
      setDocuments((a) => {
        const newA = [...a];
        newA.push(d);
        return newA;
      });    
    };

    const openDocumentHandler = (index) => {
      const document = documents[index];
      const { DocumentFile } = document;

      if (document.DocumentType === 'AgentFile') {

        const ext       = helper.getFileExt(DocumentFile.name);
        const name      = (document.DocumentName + '.' + ext).replace(/ /g, '_');
        helper.downloadAndOpenFileHandler("AgentFile", DocumentFile.name, name)
   
      }else{       
        helper.openFileHandler(DocumentFile);
      }
      
    };

    const removeDocumentHandler = (index) => {
      setDocuments((a) => {
        const nA = a.filter((b, i) => index !== i);
        return nA;
      })
    };

    const addPolicyHandler = async (e) => {
      e.preventDefault();

      var form = $('#add-policy-form');
      form.validate().destroy();
    
      form.validate({
        rules: {
          ddlClient: {
            required: true,
          },
          ddlInsuranceCompany: {
            required: true,
          },
          ddlProduct: {
            required: true,
          },
          policyNo: {
            required: true,
          },
          sumInsured: {
            required: true,
          },
          premium: {
            required: true,
          },
          tpPremium: {
            required: true,
          },
          addOnPremium: {
            required: true,
          },
          fromDate: {
            required: true,
          },
          toDate: {
            required: true,
          },
          issueDate: {
            required: true,
          },
          registrationNo: {
            required: true,
          },
          make: {
            required: true,
          },
          model: {
            required: true,
          },
        },
      });
    
      if (!form.valid()) {
        return;
      }

      try {
        setBtnLoading(true);

        if (!file) {
          swal.fire({
            icon                  : 'error',
            titleText             : 'Error!',
            text                  : "Please upload a policy",
            buttonsStyling        : false,
            confirmButtonClass    : 'btn btn-brand',
          });
          setBtnLoading(false);
          return;
        }

        const policyFileName = await uploadFile(file, "Policy");

        const policyID = await addPolicy({
          InsuranceCompanyID    : policyDetails.InsuranceCompanyID,
          ProductID             : policyDetails.ProductID,
          ClientID              : selectedClient.ID,
          PolicyNo              : policyDetails.PolicyNo,
          BusinessType          : businessType,
          IssueDate             : policyDetails.IssueDate,
          FromDate              : policyDetails.FromDate,
          ToDate                : policyDetails.ToDate,
          Premium               : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
                                : (isMotorPolicy && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) : (isMotorPolicy) ? parseInt(policyDetails.TPPremium) : parseInt(policyDetails.Premium),
          GrossPremium          : parseInt(policyDetails.GrossPremium),
          GST                   : policyDetails.GST,
          SumInsured            : parseInt(policyDetails.SumInsured),
          AdditionalData        : policyDetails.AdditionalData || '',
          FileName              : policyFileName,
          SendReminder          : policyDetails.SendReminder,
          BatchPolicyID         : (props.policyData && props.policyData.batchPolicy) ? props.policyData.batchPolicy.ID : 0
        }, paymentDetails);

        if (isMotorPolicy) {
          await postMotorPolicy({
            PolicyID          : policyID,
            RegistrationNo    : policyDetails.RegistrationNo,
            EngineNo          : policyDetails.EngineNo,
            ChassisNo          : policyDetails.ChassisNo,
            Make              : policyDetails.Make,
            Model             : policyDetails.Model,
            Type              : policyDetails.Type,
            ODPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.ODPremium),
            TPPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? parseInt(policyDetails.TPPremium)
                              : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.TPPremium) : 0,
            AddOnPremium      : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.AddOnPremium),
            NCB               : policyDetails.NCB,
          });              
        }else if (isMarinePolicy){
          await postMarinePolicy({
            PolicyID          : policyID,
            DeclarationBasis  : policyDetails.DeclarationBasis,
            PerBottomLimit    : parseInt(policyDetails.PerBottomLimit),
            PerSendingLimit   : parseInt(policyDetails.PerSendingLimit),
            IsAutoReminder    : "N",
          });
        }  

        if (documents && documents.length > 0) {

          const uploadList = documents.filter(f => f.DocumentType === 'Upload');
          let fileList = [];

          if (uploadList.length > 0)
            fileList = await uploadFileList(uploadList.map((d) => d.DocumentFile), "PolicyDocument");

          const postDocuments = [];
          documents.forEach(document => {
            postDocuments.push({
              PolicyID        : policyID,
              FileName        : document.DocumentType === 'Upload' ? fileList.find(f => f.originalname === document.DocumentFile.name).filename : document.DocumentFile.name,
              DocumentName    : document.DocumentName,
              DocumentType    : document.DocumentType === 'Upload' ? 'PolicyDocument' : document.DocumentType
            });
          });
          await addPolicyDocuments(postDocuments);
        }
        setEmailPolicyId(policyID);
        $('#add-policy-modal').addClass('modal-blur');   
        swal.fire({
          icon              : 'success',
          titleText         : 'Policy Added Successfully',
          showConfirmButton : false,
          timer             : 1500,
        });

      } catch (err) {
        swal.fire({
          icon                  : 'error',
          titleText             : 'Error!',
          text                  : err.message,
          buttonsStyling        : false,
          confirmButtonClass    : 'btn btn-brand',
        });
        setBtnLoading(false);
        return;
      }
      
    };

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="add-policy-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.policyData && props.policyData.batchPolicy ? 'Renew' : 'Add New'} Policy</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="add-policy-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                {props.policyData && props.policyData.batchPolicy && (
                                  <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                    <table className='kt-datatable__table table-striped'>
                                        <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                            <tr className='kt-datatable__row block-row'>
                                                <td className="kt-datatable__cell text-clip" width="35%">
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__pic">
                                                          <img src={props.policyData.batchPolicy.ProductIcon} alt="product"/>
                                                        </div>
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__name" title={props.policyData.batchPolicy.PolicyNo}>{props.policyData.batchPolicy.PolicyNo}</span>
                                                            <span className="kt-user-card-v2__desc">
                                                                <span title={props.policyData.batchPolicy.InsuranceCompanyName}>{props.policyData.batchPolicy.InsuranceCompanyName}</span><br/>
                                                                <span title={props.policyData.batchPolicy.ProductName}>{props.policyData.batchPolicy.ProductName}</span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={props.policyData.batchPolicy.ClientName}>
                                                    <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                            <span className="kt-user-card-v2__name">{props.policyData.batchPolicy.ClientName}</span>
                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={moment(props.policyData.batchPolicy.ToDate).format('DD-MMM-YYYY')}>
                                                        <div className="kt-user-card-v2">
                                                        <div className="kt-user-card-v2__details">
                                                            <span className="kt-user-card-v2__desc">End Date:</span>
                                                            <span className="kt-user-card-v2__name">{moment(props.policyData.batchPolicy.ToDate).format('DD/MM/YYYY')}</span>
                                            
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="kt-datatable__cell text-clip" title={props.policyData.batchPolicy.Status}>
                                                    <div className={`policy-status block-badge mr-1 badge badge-${props.policyData.batchPolicy.Status === 'Renewal Due' ? 'secondary' : (props.policyData.batchPolicy.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                        <span>{props.policyData.batchPolicy.Status}</span>
                                                    </div>
                                                </td> 
                                            </tr>                                                             
                                        </tbody>
                                    </table>
                                  </div>
                                )}
                                <div className="row">
                                    {(!props.policyData || !props.policyData.batchPolicy) && (
                                      <div className="col-lg-3">
                                        <div className="form-group">
                                            <label htmlFor="ddlBusinessType">Business Type</label>
                                            <select
                                                className="form-control kt-selectpicker"
                                                id="ddlBusinessType"
                                                value={businessType || 'New'}
                                                onChange={(event) => setBusinessType(event.target.value)}>                                                 
                                                <option value="New">New</option>
                                                <option value="Renewal">Renewal</option>
                                                <option value="Rollover">Rollover</option>
                                            </select>
                                        </div>
                                      </div>
                                    )}                
                                    <div className={`col-lg-${props.policyData && props.policyData.batchPolicy ? '12' : '9'}`}>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="ddlClient">Client</label>
                                                    <Select
                                                        value={selectedClient && {label: selectedClient.DisplayName, value: selectedClient}}
                                                        onChange={(event) => setSelectedClient(event.value)}
                                                        name="ddlClient"
                                                        placeholder="Select Client"
                                                        id="ddlClient"
                                                        className="ddlClient"
                                                        options={clients.map(c => ({
                                                          label: c.DisplayName,
                                                          value: c
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                {selectedClient ? (
                                                    <button
                                                        id="btnNewMember"
                                                        className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                                        onClick={memberAddHandler}>                          
                                                        <i className="la la-plus" />
                                                        New Member
                                                    </button>
                                                    ) : (
                                                    <button
                                                        id="btnNewClient"
                                                        className="btn btn-brand btn-block margin-0 margin-md-t24"
                                                        onClick={clientAddHandler}>                                           
                                                        <i className="la la-plus" />
                                                        New Client
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {selectedClient && (
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                        <table className="kt-datatable__table table-striped">
                                            <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                <tr className="kt-datatable__row block-row">
                                                    <td className="kt-datatable__cell text-clip">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                                <div className={`kt-badge kt-badge--xl kt-badge--${ selectedClient.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                                                    {selectedClient.DisplayName.slice(0, 1)}
                                                                </div>
                                                            </div>
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__name">{selectedClient.DisplayName}</span>
                                                                <span className="kt-user-card-v2__desc">{selectedClient.Type === 'Corporate' ? 'Organization' : selectedClient.Type}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip">
                                                        <span>
                                                            {selectedClient.Email || ''}
                                                            {selectedClient.Mobile ? `${selectedClient.Email ? ` | ${selectedClient.Mobile}` : selectedClient.Mobile}` : ''}
                                                        </span>
                                                    </td>
                                                    <td className="text-right padding-r20">
                                                        <button 
                                                            type="button"                    
                                                            onClick={(e) => setSelectedClient(null)}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>                           
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                                {(selectedClient && !file) && (<Dropzone fileHandler={fileHandler} prompt="Drop policy file here or click to select"/>)}

                                {file && (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                    <a className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={() => helper.openFileHandler(file)}>
                                                          <img height="30px" width="30px" src={helper.getFileLogo(file.name)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={() => helper.openFileHandler(file)}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {file.name}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={removeFileHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                                            <Select
                                                                value={policyDetails && policyDetails.InsuranceCompanyID && {label: insuranceCompanies.find(c => c.ID == policyDetails.InsuranceCompanyID).Name, value: policyDetails.InsuranceCompanyID}}
                                                                onChange={(event) => updatePolicyDetails('InsuranceCompanyID', event.value)}
                                                                name="ddlInsuranceCompany"
                                                                id="ddlInsuranceCompany"
                                                                placeholder="Select Company"
                                                                options={insuranceCompanies.map(c => ({
                                                                  label: c.Name,
                                                                  value: c.ID
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.user.categoryId != 3 && (
                                                      <div className="col-4">
                                                        <button
                                                          id="btnNewCompany"
                                                          className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                          data-toggle="kt-popover"
                                                          data-trigger="hover"
                                                          data-placement="bottom"
                                                          data-content="Add Insurance Company"
                                                          onClick={companyAddHandler}>
                                                          <i className="la la-plus" />
                                                          New
                                                        </button>
                                                      </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                        <div className="form-group">
                                                            <label htmlFor="ddlProduct">Product</label>
                                                            <Select
                                                                value={policyDetails && policyDetails.ProductID && {label: products.find(c => c.ID == policyDetails.ProductID).Name, value: policyDetails.ProductID}}
                                                                onChange={(event) => updatePolicyDetails('ProductID', event.value)}
                                                                name="ddlProduct"
                                                                id="ddlProduct"
                                                                placeholder="Select Product"
                                                                options={products.map(c => ({
                                                                  label: c.Name,
                                                                  value: c.ID
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    {userDetails.user.categoryId != 3 && (
                                                      <div className="col-4">
                                                        <button
                                                          id="btnNewProduct"
                                                          className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                          data-toggle="kt-popover"
                                                          data-trigger="hover"
                                                          data-placement="bottom"
                                                          data-content="Add Product"
                                                          onClick={productAddHandler}>           
                                                          <i className="la la-plus" />
                                                          New
                                                        </button>
                                                      </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                          <div className={`${isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 1 && policyDetails.ProductID != 3 && policyDetails.ProductID != 20 && policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                            <div className="form-group">
                                              <label htmlFor="policyNo">Policy No.</label>
                                              <input
                                                id="policyNo"
                                                value={policyDetails.PolicyNo || ''}
                                                name="policyNo"
                                                className="form-control margin-b10"
                                                type="text"
                                                onChange={(event) => updatePolicyDetails('PolicyNo', event.target.value)}               
                                              />                     
                                            </div>
                                          </div>
                                          <div className={`${isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2' : 'col-md-4'} margin-b0`}>
                                            <div className="form-group">
                                              <label htmlFor="sumInsured">Sum Insured</label>
                                              <input
                                                id="sumInsured"
                                                value={policyDetails.SumInsured || ''}
                                                name="sumInsured"
                                                className="form-control margin-b10"
                                                type="number"
                                                onChange={(event) => updatePolicyDetails('SumInsured', event.target.value)}                                                   
                                              />
                                            </div>
                                          </div>
                                            {!isMotorPolicy && (
                                              <div className="col-md-4 margin-b0">
                                                <div className="form-group">
                                                  <label htmlFor="premium">Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                                                  <input
                                                    id="premium"
                                                    value={policyDetails.Premium || ''}
                                                    name="premium"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            {isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21)) && (
                                                <div className="col-md-4 margin-b0">
                                                    <div className="form-group">
                                                        <label htmlFor="premium">TP Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                                                        <input
                                                            id="premium"
                                                            value={policyDetails.TPPremium}
                                                            name="premium"
                                                            className="form-control margin-b10"
                                                            type="number"
                                                            onChange={(event) => updatePolicyDetails('TPPremium', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                                                <>
                                                    <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2 ' : 'col-md-3'} margin-b0`}>
                                                        <div className="form-group">
                                                            <label htmlFor="premium">OD Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                            <input
                                                                id="premium"
                                                                value={policyDetails.ODPremium}
                                                                name="premium"
                                                                className="form-control margin-b10"
                                                                type="number"
                                                                onChange={(event) => updatePolicyDetails('ODPremium', event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 margin-b0">
                                                        <div className="form-group">
                                                            <label htmlFor="addOnPremium">Add On Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                            <input
                                                                id="addOnPremium"
                                                                value={policyDetails.AddOnPremium}
                                                                name="addOnPremium"
                                                                className="form-control margin-b10"
                                                                type="number"
                                                                onChange={(event) => updatePolicyDetails('AddOnPremium', event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) && (
                                                        <div className="col-md-2 margin-b0">
                                                            <div className="form-group">
                                                                <label htmlFor="tpPremium">TP Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                                <input
                                                                    id="tpPremium"
                                                                    value={policyDetails.TPPremium}
                                                                    name="tpPremium"
                                                                    className="form-control margin-b10"
                                                                    type="number"
                                                                    onChange={(event) => updatePolicyDetails('TPPremium', event.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                        <div className='row'>
                                          <div className='col-4'>
                                            <label htmlFor="gst">GST</label>
                                            <div className='input-group'>
                                              <input
                                                id="gst"
                                                value={policyDetails.GST}
                                                name="gst"
                                                className="form-control"
                                                max='100'
                                                type="number"
                                                onChange={(event) => updatePolicyDetails('GST', event.target.value)}                                                   
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text">%</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className={`${'col-4'} margin-b0`}>
                                            <div className="form-group">
                                              <label htmlFor="grossPremium">Gross Premium</label>
                                              <input
                                                id="grossPremium"
                                                value={policyDetails.GrossPremium || ''}
                                                name="grossPremium"
                                                className="form-control margin-b10"
                                                type="number"
                                                onChange={(event) => updatePolicyDetails('GrossPremium', event.target.value)}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-4">
                                            <button
                                              id="btnNewProduct"
                                              className="btn btn-label-brand btn-block margin-0 margin-t24"
                                              data-toggle="kt-popover"
                                              data-trigger="hover"
                                              data-placement="bottom"
                                              data-content={`${paymentDetails.PaymentType != 0 ? 'Update' : 'Add'} Payment Mode`}
                                              onClick={() => setShowPaymentModal(true)}  
                                            >         
                                              <i className="la la-plus" />
                                              {`${paymentDetails.PaymentType != 0 ? 'Update' : 'Add'} Payment Mode`}
                                            </button>
                                          </div>
                                        </div>
                                        <div className="row">
                                            <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                                <div className="form-group">
                                                    <label htmlFor="fromDate">Start Date</label>
                                                    <input
                                                        id="fromDate"
                                                        value={policyDetails.FromDate || ''}
                                                        name="fromDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('FromDate', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                                <div className="form-group">
                                                    <label htmlFor="toDate">End Date</label>
                                                    <input
                                                        id="toDate"
                                                        value={policyDetails.ToDate || ''}
                                                        name="toDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('ToDate', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                                <div className="form-group">
                                                    <label htmlFor="issueDate">Issued On</label>
                                                    <input
                                                        id="issueDate"
                                                        value={policyDetails.IssueDate || ''}
                                                        name="issueDate"
                                                        className="form-control margin-b10"
                                                        type="date"
                                                        onChange={(event) => updatePolicyDetails('IssueDate', event.target.value)}                                            
                                                    />
                                                </div>
                                            </div>
                                            {showRenewalBtn && (
                                              <div className="col-md-3 margin-b0">
                                                <div className="form-group">
                                                  <label htmlFor="issueDate">Send Renewal Reminder</label>
                                                  <span className="kt-switch kt-switch--icon">
                                                    <label>  
                                                      <input 
                                                        id="chkActive" 
                                                        name="chkActive" 
                                                        type="checkbox"
                                                        defaultChecked={policyDetails.SendReminder && policyDetails.SendReminder == 'N' ? '' : 'checked'}
                                                        onChange={(event) => updatePolicyDetails('SendReminder', event.target.checked ? 'Y' : 'N')} 
                                                      />
                                                      <span></span> 
                                                    </label>
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                        {isMotorPolicy && 
                                        (
                                          <>
                                            <div className="row">
                                              <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="registrationNo">Registration No.</label>
                                                    <input
                                                        id="registrationNo"
                                                        value={policyDetails.RegistrationNo || ''}
                                                        name="registrationNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('RegistrationNo', event.target.value)}               
                                                    />                     
                                                </div>
                                              </div>
                                              <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="make">Make</label>
                                                    <input
                                                        id="make"
                                                        value={policyDetails.Make || ''}
                                                        name="make"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('Make', event.target.value)}               
                                                    />                     
                                                </div>
                                              </div>
                                              <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="model">Model</label>
                                                    <input
                                                        id="model"
                                                        value={policyDetails.Model || ''}
                                                        name="model"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('Model', event.target.value)}               
                                                    />                     
                                                </div>
                                              </div>
                                              <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                                                <div className="form-group">
                                                  <label htmlFor="ddlType">Type</label>
                                                  <select
                                                      className="form-control kt-selectpicker"
                                                      id="ddlType"
                                                      value={policyDetails.Type || 'Petrol'}
                                                      onChange={(event) => updatePolicyDetails('Type', event.target.value)}>                                                 
                                                      <option value="Petrol">Petrol</option>
                                                      <option value="Diesel">Diesel</option>
                                                      <option value="Electric">Electric</option>
                                                  </select>
                                                </div>
                                              </div>
                                              {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                                                <div className='col-md-2'>
                                                  <div className="form-group">
                                                    <label htmlFor="ncb">NCB</label>
                                                    <div className='input-group'>
                                                      <input
                                                        name="ncb"
                                                        id="ncb"
                                                        value={policyDetails.NCB}
                                                        max='100'
                                                        className="form-control"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('NCB', event.target.value)}                                                   
                                                      />
                                                      <div className="input-group-append">
                                                        <span className="input-group-text">%</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                            <div className="row">
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="EngineNo">Engine No.</label>
                                                    <input
                                                        id="engineNo"
                                                        value={policyDetails.EngineNo || ''}
                                                        name="engineNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('EngineNo', event.target.value)}               
                                                    />                     
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="ChassisNo">Chassis No.</label>
                                                    <input
                                                        id="chassisNo"
                                                        value={policyDetails.ChassisNo || ''}
                                                        name="chassisNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('ChassisNo', event.target.value)}               
                                                    />                     
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                        {isMarinePolicy && 
                                        (
                                        <div className="row">
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <label htmlFor="ddlDeclarationBasis">Declaration Basis</label>
                                              <select
                                                  className="form-control kt-selectpicker"
                                                  id="ddlDeclarationBasis"
                                                  onChange={(event) => updatePolicyDetails('DeclarationBasis', event.target.value)}>                                                 
                                                  <option value="Monthly">Monthly</option>
                                                  <option value="Certificate Issuance">Certificate Issuance</option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <label htmlFor="perBottomLimit">Per Bottom Limit</label>
                                              <input
                                                  id="perBottomLimit"
                                                  value={policyDetails.PerBottomLimit || ''}
                                                  name="perBottomLimit"
                                                  className="form-control margin-b10"
                                                  type="number"
                                                  onChange={(event) => updatePolicyDetails('PerBottomLimit', event.target.value)}
                                              />
                                            </div>
                                          </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="perBottomLimit">Per Sending Limit</label>
                                                    <input
                                                        id="perSendingLimit"
                                                        value={policyDetails.PerSendingLimit || ''}
                                                        name="perSendingLimit"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('PerSendingLimit', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        <div className="row">
                                          <div className="col-md-12 margin-b20">
                                            <CustomEditor
                                                placeholder="Additional Details"
                                                addQuickText={setAddQuickText}
                                                fillQuickText={fillQuickText}
                                                setFillQuickText={setFillQuickText}
                                                setData={setEditorData}
                                            />     
                                          </div>
                                        </div>
                                        {documents && documents.length > 0 && 
                                          (
                                            <div className="row">
                                              <div className="col-12">
                                                  <div className="kt-notification margin-b30">
                                                      {
                                                        documents.map((d, i) =>
                                                          <a key={i} className="kt-notification__item">
                                                            <div className="kt-notification__item-icon margin-r10" onClick={() => openDocumentHandler(i)}>
                                                              <img height="30px" width="30px" src={helper.getFileLogo(d.DocumentFile.name)} />
                                                            </div>
                                                            <div className="kt-notification__item-details" onClick={() => openDocumentHandler(i)}>
                                                                <div className="kt-notification__item-title kt-font-bold">
                                                                    {d.DocumentName}
                                                                </div>
                                                            </div>
                                                            <button 
                                                                type="button"                    
                                                                onClick={() => removeDocumentHandler(i)}
                                                                className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                                <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                            </button>
                                                          </a>
                                                        )
                                                      }
                                                  </div>
                                              </div>
                                            </div>
                                          )}       
                                    </React.Fragment>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            {file && (
                              <button
                                onClick={() => setAddDocument(true)}
                                type="button"
                                className="btn btn-label-brand margin-0 margin-r5"
                                disabled={btnLoading} >                 
                                <i className="flaticon-doc"></i> 
                                Attach Documents
                              </button>
                            )}
                            <button
                                id="save-btn"
                                onClick={addPolicyHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} >                  
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {selectedClient && (
              <React.Fragment>
                  <AddMember
                      addMemberId={setAddedMemberId}
                      groupId={selectedClient.GroupID}
                      client={getClientName()}
                      from="#add-policy-modal"
                  />
              </React.Fragment>
            )} 

            {file && (
              <React.Fragment>
                  <AddCompany
                      type={addModalType}
                      addInsuranceCompanyId={setAddedCompanyId}
                      addProductId={setAddedProductId}
                      from="#add-policy-modal"
                  />
                  {addDocument && (
                    <AddDocument
                      action="a"
                      setDocumentHandler={setDocumentHandler}
                      addDocument={setAddDocument}
                      from="#add-policy-modal"
                    />
                  )}                    
                  {showPaymentModal && (
                    <PolicyPaymentModal
                      from="#add-policy-modal"
                      setPaymentDetails={setPaymentDetails}
                      paymentDetails={paymentDetails}
                      onDismissModal={() => setShowPaymentModal(false)}
                    />
                  )}
              </React.Fragment>
            )}


            {emailPolicyId && (
              <PolicyCommissionModal
                  action="a"
                  from='mainAgentPolicyStats'
                  policyDetails={policyDetails}
                  productName={products.find(c => c.ID == policyDetails.ProductID).Name}
                  isMotorPolicy={isMotorPolicy}
                  groupId={selectedClient.GroupID}
                  policyId={emailPolicyId}
                  refetchPolicies={refetchPolicies}
              />
            )} 

            {addQuickText && (
              <AddQuickText
                  fillQuickText={setFillQuickText}
                  addQuickText={setAddQuickText}
                  from="#add-policy-modal"
              />
            )} 

        </React.Fragment>
    );

};

export default AddPolicy;