import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import Loading from '../global/loading';
import { getClientsByGroupIDAndCompanyID } from '../api/clientAPI';
import { sendPolicyEmail, getPolicyDocuments, postPolicyCommission, getPolicyCommssionByPolicyID} from '../api/policyAPI';
import swal from 'sweetalert2';
import Select from 'react-select';
import { getAgentsByAgentCompanyID } from '../api/agentAPI';
import { getAuthState } from '../../assets/scripts/login-util';
import EmailPolicy from './emailPolicy';


const PolicyCommissionModal = (props) => {

    const [btnLoading,             setBtnLoading]             = useState(false); 
    const [commission,             setCommission]             = useState({ TDS: 0, Percent: 0, Commission: 0, ODPercent : 0, AOPercent: 0, TPPercent: 0, ODCommission: 0, AOCommission: 0, TPCommission: 0 });
    const [showEmailModal,         setShowEmailModal]         = useState(false)
    const [calculationType,        setCalculationType]        = useState('Commission')

    useEffect(() => {
        $('#commission-policy-modal').modal({
            backdrop: 'static',
        });
    
        $('#commission-policy-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');   
            if (props.action === 'e') props.onDismissModal(); 
            // $('#search-policy-modal').removeClass('modal-blur');       
        });
        // $('#search-policy-modal').addClass('modal-blur');
    
    }, []);

    useEffect(() => {
        if (props.action == 'e') {
            fetchPolicyCommssionByPolicyID(props.policyId)
        }
    }, [props])

    useEffect(() => {
        if (commission.Commission || commission.Commission == 0) {
            const percent = (commission.Commission * commission.TDS) / 100;
            const totalPayout = commission.Commission - percent
            setCommission(prev => ({...prev, TotalPayout: totalPayout}))
        }
    }, [commission.Commission, commission.TDS])

    useEffect(() => {
        if (props.isMotorPolicy && (props.policyDetails && props.policyDetails.ProductID && (props.policyDetails.ProductID == 2 || props.policyDetails.ProductID == 4 || props.policyDetails.ProductID == 21))) {
            if (commission.TPCommission || commission.TPCommission == 0) {
                const percent = (commission.TPCommission * commission.TDS) / 100;
                const totalPayout = commission.TPCommission - percent
                setCommission(prev => ({...prev, TotalPayout: totalPayout}))
            }
        }
    }, [commission.TPCommission, commission.TDS]);

    useEffect(() => {
        if (props.isMotorPolicy && (props.policyDetails && props.policyDetails.ProductID && (props.policyDetails.ProductID != 2 && props.policyDetails.ProductID != 4 && props.policyDetails.ProductID != 21))) {
            if (commission.ODCommission || commission.ODCommission == 0 || commission.AOCommission || commission.AOCommission == 0 || commission.TPCommission || commission.TPCommission == 0) {
                const totalCommission = parseFloat(commission.ODCommission) + parseFloat(commission.AOCommission) + parseFloat(commission.TPCommission)
                const percent = (totalCommission * commission.TDS) / 100;
                const totalPayout = totalCommission - percent
                setCommission(prev => ({...prev, TotalPayout: totalPayout}))
            }
        }
    }, [commission.ODCommission, commission.AOCommission, commission.TPCommission, commission.TDS]);

    // useEffect(() => {
    //     if (commission.Percent) {
    //         const calculatedCommission = (props.policyDetails.Premium * commission.Percent) / 100;
    //         // Avoid updating state if the value hasn't changed (prevent infinite loop)
    //         if (commission.Commission !== calculatedCommission) {
    //             setCommission((prev) => ({ ...prev, Commission: calculatedCommission }));
    //         }
    //     }
    // }, [commission.Percent]);
    
    // useEffect(() => {
    //     if (commission.Commission) {
    //         const calculatedPercent = (commission.Commission / props.policyDetails.Premium) * 100;
    //         // Avoid updating state if the value hasn't changed (prevent infinite loop)
    //         if (commission.Percent !== calculatedPercent) {
    //             setCommission((prev) => ({ ...prev, Percent: calculatedPercent }));
    //         }
    //     }
    // }, [commission.Commission]);
    
    // useEffect(() => {
    //     if (commission.ODPercent) {
    //         const calculatedODCommission = (props.policyDetails.ODPremium * commission.ODPercent) / 100;
    //         if (commission.ODCommission !== calculatedODCommission) {
    //             setCommission((prev) => ({ ...prev, ODCommission: calculatedODCommission }));
    //         }
    //     }
    // }, [commission.ODPercent]);
    
    // useEffect(() => {
    //     if (commission.ODCommission) {
    //         const calculatedODPercent = (commission.ODCommission / props.policyDetails.ODPremium) * 100;
    //         if (commission.ODPercent !== calculatedODPercent) {
    //             setCommission((prev) => ({ ...prev, ODPercent: calculatedODPercent }));
    //         }
    //     }
    // }, [commission.ODCommission]);
    
    // useEffect(() => {
    //     if (commission.AOPercent) {
    //         const calculatedAOCommission = (props.policyDetails.AddOnPremium * commission.AOPercent) / 100;
    //         if (commission.AOCommission !== calculatedAOCommission) {
    //             setCommission((prev) => ({ ...prev, AOCommission: calculatedAOCommission }));
    //         }
    //     }
    // }, [commission.AOPercent]);
    
    // useEffect(() => {
    //     if (commission.AOCommission) {
    //         const calculatedAOPercent = (commission.AOCommission / props.policyDetails.AddOnPremium) * 100;
    //         if (commission.AOPercent !== calculatedAOPercent) {
    //             setCommission((prev) => ({ ...prev, AOPercent: calculatedAOPercent }));
    //         }
    //     }
    // }, [commission.AOCommission]);
    
    // useEffect(() => {
    //     if (commission.TPPercent) {
    //         const calculatedTPCommission = (props.policyDetails.TPPremium * commission.TPPercent) / 100;
    //         if (commission.TPCommission !== calculatedTPCommission) {
    //             setCommission((prev) => ({ ...prev, TPCommission: calculatedTPCommission }));
    //         }
    //     }
    // }, [commission.TPPercent]);
    
    // useEffect(() => {
    //     if (commission.TPCommission) {
    //         const calculatedTPPercent = (commission.TPCommission / props.policyDetails.TPPremium) * 100;
    //         if (commission.TPPercent !== calculatedTPPercent) {
    //             setCommission((prev) => ({ ...prev, TPPercent: calculatedTPPercent }));
    //         }
    //     }
    // }, [commission.TPCommission]);

    const CalculateCommission = () => {
        if (commission.Percent) {
            const calculatedCommission = (props.policyDetails.Premium * commission.Percent) / 100;
            const roundedCommission = parseFloat(calculatedCommission.toFixed(2)); // Round to 2 decimal places
            // Avoid updating state if the value hasn't changed (prevent infinite loop)
            if (commission.Commission !== roundedCommission) {
                setCommission((prev) => ({ ...prev, Commission: roundedCommission }));
            }
        }
    }

    const CalculatePercent = () => {
        if (commission.Commission) {
            const calculatedPercent = (commission.Commission / props.policyDetails.Premium) * 100;
            const roundedPercent = parseFloat(calculatedPercent.toFixed(2)); // Round to 2 decimal places
            // Avoid updating state if the value hasn't changed (prevent infinite loop)
            if (commission.Percent !== roundedPercent) {
                setCommission((prev) => ({ ...prev, Percent: roundedPercent }));
            }
        }
    }

    const CalculateTPCommission = () => {
        if (commission.TPPercent) {
            const calculatedTPCommission = (props.policyDetails.TPPremium * commission.TPPercent) / 100;
            if (commission.TPCommission !== calculatedTPCommission) {
                setCommission((prev) => ({ ...prev, TPCommission: calculatedTPCommission }));
            }
        }
    }

    const CalculateTPPercent = () => {
        if (commission.TPCommission) {
            const calculatedTPPercent = (commission.TPCommission / props.policyDetails.TPPremium) * 100;
            if (commission.TPPercent !== calculatedTPPercent) {
                setCommission((prev) => ({ ...prev, TPPercent: calculatedTPPercent }));
            }
        }
    }

    const CalculateMotorCommission = () => {

        
        if (calculationType == 'Payout') {
            if (commission.ODCommission) {
                const calculatedODPercent = (commission.ODCommission / props.policyDetails.ODPremium) * 100;
                if (commission.ODPercent !== calculatedODPercent) {
                    setCommission((prev) => ({ ...prev, ODPercent: calculatedODPercent }));
                }
            }
    
            if (commission.AOCommission) {
                const calculatedAOPercent = (commission.AOCommission / props.policyDetails.AddOnPremium) * 100;
                if (commission.AOPercent !== calculatedAOPercent) {
                    setCommission((prev) => ({ ...prev, AOPercent: calculatedAOPercent }));
                }
            }
            if (commission.TPCommission) {
                const calculatedTPPercent = (commission.TPCommission / props.policyDetails.TPPremium) * 100;
                if (commission.TPPercent !== calculatedTPPercent) {
                    setCommission((prev) => ({ ...prev, TPPercent: calculatedTPPercent }));
                }
            }
        } else {

            if (commission.ODPercent) {
                const calculatedODCommission = (props.policyDetails.ODPremium * commission.ODPercent) / 100;
                if (commission.ODCommission !== calculatedODCommission) {
                    setCommission((prev) => ({ ...prev, ODCommission: calculatedODCommission }));
                }
            }

            if (commission.AOPercent) {
                const calculatedAOCommission = (props.policyDetails.AddOnPremium * commission.AOPercent) / 100;
                if (commission.AOCommission !== calculatedAOCommission) {
                    setCommission((prev) => ({ ...prev, AOCommission: calculatedAOCommission }));
                }
            }

            if (commission.TPPercent) {
                const calculatedTPCommission = (props.policyDetails.TPPremium * commission.TPPercent) / 100;
                if (commission.TPCommission !== calculatedTPCommission) {
                    setCommission((prev) => ({ ...prev, TPCommission: calculatedTPCommission }));
                }
            }
        }
    }

    const skipHandler = async (e) => {
        e.preventDefault();
        if (props.action === 'a') {
        //   if (props.from == 'subAgentPolicyStats') $('#add-policy-modal').modal('hide')
        //   props.refetchPolicies(1);   
            setShowEmailModal(true)
        } 
        $('#commission-policy-modal').modal('hide');        
    }

    const fetchPolicyCommssionByPolicyID = async (policyID) => {
        try {
            const response = await getPolicyCommssionByPolicyID(policyID);
            setCommission(response)
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const postPolicyCommissionHandler = async () => {
        try {
            await postPolicyCommission({...commission, PolicyID: props.policyId})
            if (props.action == 'a') {
                setShowEmailModal(true)
            } else {
                props.refetchPolicies()
                swal.fire({
                    toast             : true,
                    icon              : 'success',
                    titleText         : `Commission saved successfully!`,
                    position          : 'bottom-end',
                    showConfirmButton : false,
                    timer             : 1500,
                    animation         : false,
                    customClass       : {
                        popup: 'margin-20',
                    },
                });
            }
            $('#commission-policy-modal').modal('hide');
            
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
        }  
    }
  
    return (
        <>
            <div
                className="modal fade"
                id="commission-policy-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
        
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.action == 'e' ? 'Update' : 'Add'} Commission</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={skipHandler}
                                disabled={btnLoading}
                                aria-label="Skip">
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="contact-modal-holder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="form-group row">
                                    <label className="col-form-label col-lg-3">Product</label>
                                    <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                        <input
                                            id="productName"
                                            value={props.productName || ''}
                                            name="productName"
                                            disabled
                                            className="form-control"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                {!props.isMotorPolicy && (
                                    <>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Premium</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="Premium"
                                                    value={props.policyDetails.Premium}
                                                    name="Premium"
                                                    disabled
                                                    className="form-control"
                                                    type="text"                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Calculation In</label>
                                            <div className="col-xl-6 col-lg-8">
                                                <select
                                                    className="form-control kt-selectpicker"
                                                    id="ddlCategory"
                                                    value={calculationType}
                                                    onChange={(event) => setCalculationType(event.target.value)}>                                                 
                                                    <option value="Commission">% Percentage</option>
                                                    <option value="Payout">₹ Payout</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Percentage</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-6">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="Percent"
                                                    value={commission.Percent}
                                                    name="Percent"
                                                    className="form-control"
                                                    type="number"
                                                    disabled={calculationType == 'Payout'}
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, Percent: (target.value)}))}   
                                                />
                                                
                                            </div>
                                            {calculationType == 'Commission' && (
                                                <button className='btn btn-sm btn-success col-lg-2 margin-0' onClick={CalculateCommission}>Calculate</button>
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-6">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">₹</span>
                                                </div>
                                                <input
                                                    id="Commission"
                                                    value={commission.Commission}
                                                    name="Commission"
                                                    className="form-control"
                                                    type="number"
                                                    disabled={calculationType == 'Commission'}
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, Commission: (target.value)}))}                                                 
                                                />
                                            </div>
                                            {calculationType == 'Payout' && (
                                                <button className='btn btn-sm btn-success col-lg-2 margin-0' onClick={CalculatePercent}>Calculate</button>
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TDS</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-6">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="TDS"
                                                    value={commission.TDS || 0}
                                                    name="TDS"
                                                    className="form-control"
                                                    type="number"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, TDS: (target.value)}))}   
                                                />
                                                
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Total Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="TotalPayout"
                                                    value={commission.TotalPayout || 0}
                                                    name="TotalPayout"
                                                    disabled
                                                    className="form-control"
                                                    type="number"                                           
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {props.isMotorPolicy && (props.policyDetails && props.policyDetails.ProductID && (props.policyDetails.ProductID == 2 || props.policyDetails.ProductID == 4 || props.policyDetails.ProductID == 21)) && (
                                    <>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Calculation In</label>
                                            <div className="col-xl-6 col-lg-8">
                                                <select
                                                    className="form-control kt-selectpicker"
                                                    id="ddlCategory"
                                                    value={calculationType}
                                                    onChange={(event) => setCalculationType(event.target.value)}>                                                 
                                                    <option value="Commission">% Percentage</option>
                                                    <option value="Payout">₹ Payout</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TP Premium</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="Premium"
                                                    value={props.policyDetails.TPPremium}
                                                    name="Premium"
                                                    disabled
                                                    className="form-control"
                                                    type="text"                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TP Percentage</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="Percent"
                                                    value={commission.TPPercent}
                                                    name="Percent"
                                                    className="form-control"
                                                    type="text"
                                                    disabled={calculationType == 'Payout'}
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, TPPercent: (target.value)}))}                                                 
                                                />
                                            </div>
                                            {calculationType == 'Commission' && (
                                                <button className='btn btn-sm btn-success col-lg-2 margin-0' onClick={CalculateTPCommission}>Calculate</button>
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TP Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">₹</span>
                                                </div>
                                                <input
                                                    id="Commission"
                                                    value={commission.TPCommission}
                                                    name="Commission"
                                                    className="form-control"
                                                    type="text"
                                                    disabled={calculationType == 'Commission'}
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, TPCommission: (target.value)}))}                                                 
                                                />
                                            </div>
                                            {calculationType == 'Payout' && (
                                                <button className='btn btn-sm btn-success col-lg-2 margin-0' onClick={CalculateTPPercent}>Calculate</button>
                                            )}
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TDS</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-6">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="TDS"
                                                    value={commission.TDS || 0}
                                                    name="TDS"
                                                    className="form-control"
                                                    type="number"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, TDS: (target.value)}))}   
                                                />
                                                
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Total Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="TotalPayout"
                                                    value={commission.TotalPayout || 0}
                                                    name="TotalPayout"
                                                    disabled
                                                    className="form-control"
                                                    type="number"                                               
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                {props.isMotorPolicy && (props.policyDetails && props.policyDetails.ProductID && (props.policyDetails.ProductID != 2 && props.policyDetails.ProductID != 4 && props.policyDetails.ProductID != 21)) && (
                                    <>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Calculation In</label>
                                            <div className="col-xl-6 col-lg-8">
                                                <select
                                                    className="form-control kt-selectpicker"
                                                    id="ddlCategory"
                                                    value={calculationType}
                                                    onChange={(event) => setCalculationType(event.target.value)}>                                                 
                                                    <option value="Commission">% Percentage</option>
                                                    <option value="Payout">₹ Payout</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">OD Premium</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="Premium"
                                                    value={props.policyDetails.ODPremium}
                                                    name="Premium"
                                                    disabled
                                                    className="form-control"
                                                    type="text"                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">OD Percentage</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="Percent"
                                                    value={commission.ODPercent}
                                                    name="Percent"
                                                    disabled={calculationType == 'Payout'}
                                                    className="form-control"
                                                    type="text"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, ODPercent: (target.value)}))}                                                 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">OD Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">₹</span>
                                                </div>
                                                <input
                                                    id="Commission"
                                                    value={commission.ODCommission}
                                                    name="Commission"
                                                    disabled={calculationType == 'Commission'}
                                                    className="form-control"
                                                    type="text"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, ODCommission: (target.value)}))}                                                 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">AO Premium</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="Premium"
                                                    value={props.policyDetails.AddOnPremium}
                                                    name="Premium"
                                                    disabled
                                                    className="form-control"
                                                    type="text"                                               
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">AO Percentage</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="Percent"
                                                    value={commission.AOPercent}
                                                    disabled={calculationType == 'Payout'}
                                                    name="Percent"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, AOPercent: (target.value)}))}                                                 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">AO Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">₹</span>
                                                </div>
                                                <input
                                                    id="Commission"
                                                    value={commission.AOCommission}
                                                    name="Commission"
                                                    disabled={calculationType == 'Commission'}
                                                    className="form-control"
                                                    type="text"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, AOCommission: (target.value)}))}                                                 
                                                />
                                            </div>
                                        </div>
                                        {(props.policyDetails && props.policyDetails.ProductID && (props.policyDetails.ProductID == 1 || props.policyDetails.ProductID == 3 || props.policyDetails.ProductID == 20)) && (
                                            <>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">TP Premium</label>
                                                    <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                        <input
                                                            id="Premium"
                                                            value={props.policyDetails.TPPremium}
                                                            name="Premium"
                                                            disabled
                                                            className="form-control"
                                                            type="text"                                               
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">TP Percentage</label>
                                                    <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">%</span>
                                                        </div>
                                                        <input
                                                            id="Percent"
                                                            value={commission.TPPercent}
                                                            disabled={calculationType == 'Payout'}
                                                            name="Percent"
                                                            className="form-control"
                                                            type="text"
                                                            onChange={({ target }) => setCommission((prev) => ({...prev, TPPercent: (target.value)}))}                                                 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-form-label col-lg-3">TP Payout</label>
                                                    <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text">₹</span>
                                                        </div>
                                                        <input
                                                            id="Commission"
                                                            value={commission.TPCommission}
                                                            name="Commission"
                                                            disabled={calculationType == 'Commission'}
                                                            className="form-control"
                                                            type="text"
                                                            onChange={({ target }) => setCommission((prev) => ({...prev, TPCommission: (target.value)}))}                                                 
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3"></label>
                                            <button className='btn btn-sm btn-success col-lg-2 margin-0' onClick={CalculateMotorCommission}>Calculate</button>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">TDS</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-6">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">%</span>
                                                </div>
                                                <input
                                                    id="TDS"
                                                    value={commission.TDS || 0}
                                                    name="TDS"
                                                    className="form-control"
                                                    type="number"
                                                    onChange={({ target }) => setCommission((prev) => ({...prev, TDS: (target.value)}))}   
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Total Payout</label>
                                            <div className="input-group kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="TotalPayout"
                                                    value={commission.TotalPayout || 0}
                                                    name="TotalPayout"
                                                    disabled
                                                    className="form-control"
                                                    type="number"                                              
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                onClick={skipHandler}
                                disabled={btnLoading}>    
                                {props.action === 'a' ? 'Skip Commission' : 'Close'}
                            </button>
                            <button
                                onClick={postPolicyCommissionHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                    btnLoading
                                        ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                        : ''
                                    }`}
                                disabled={btnLoading} >   
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showEmailModal && (
                <EmailPolicy
                    action="a"
                    from={props.from}
                    groupId={props.groupId}
                    policyId={props.policyId}
                    refetchPolicies={props.refetchPolicies}
                />
            )} 
        </>
    )
}

export default PolicyCommissionModal