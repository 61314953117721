import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import 'jquery-form';
import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
import '../../assets/scripts/jquery-validation.init';
import * as helper from '../global/helper';
import CustomEditor from '../global/editor';
import AddQuickText from '../global/quickText';
import swal from 'sweetalert2';
import Select from 'react-select';
import Dropzone from '../global/dropzone';
import { getClients, getClientsByGroupIDAndCompanyID, getMainClients, getMainClientsByAgentID } from '../api/clientAPI';
import AddMember from '../clients/addMember';
import AddCompany from './addCompany';
import { postMarinePolicy, postMotorPolicy, getInsuranceCompanies, getMarinePolicy, getMotorPolicy, getProducts, updatePolicy, getInsuranceCompaniesByAgentID, getProductsByAgentID, getPolicyPaymentModeByPolicyID } from '../api/policyAPI';
import moment from 'moment';
import { uploadFile } from '../api/fileAPI';
import { getAuthState } from '../../assets/scripts/login-util';
import PolicyPaymentModal from './policyPaymentModal';

const EditPolicy = (props) => {

    const [btnLoading,          setBtnLoading]          = useState(false);
    const [policyDetails,       setPolicyDetails]       = useState(props.policyDetails);
    const [businessType,        setBusinessType]        = useState(props.policyDetails.BusinessType);
    const [selectedClient,      setSelectedClient]      = useState(null);
    const [clients,             setClients]             = useState([]);
    const [addedClientId,       setAddedClientId]       = useState(null);
    const [addedMemberId,       setAddedMemberId]       = useState(null);
    const [fileName,            setFileName]            = useState(null);
    const [file,                setFile]                = useState(null);
    const [insuranceCompanies,  setInsuranceCompanies]  = useState([]);
    const [products,            setProducts]            = useState([]);
    const [addedCompanyId,      setAddedCompanyId]      = useState(null);
    const [addedProductId,      setAddedProductId]      = useState(null);
    const [addModalType,        setAddModalType]        = useState('Company');
    const [isMotorPolicy,       setIsMotorPolicy]       = useState(props.policyDetails.ExtraFields === 'MotorPolicy' ? true : false);
    const [isMarinePolicy,      setIsMarinePolicy]      = useState(props.policyDetails.ExtraFields === 'MarinePolicy' ? true : false);
    const [addQuickText,        setAddQuickText]        = useState(false);
    const [fillQuickText,       setFillQuickText]       = useState(props.policyDetails.AdditionalData);
    const [showRenewalBtn,      setShowRenewalBtn]      = useState(props.policyDetails.SendReminder === 'Y' ? true : false);
    const [lastUpdatedField,    setLastUpdatedField]    = useState(null);
    const [paymentDetails,      setPaymentDetails]      = useState({ PaymentType: 0 });
    const [showPaymentModal,    setShowPaymentModal]    = useState(false);

    const userDetails = getAuthState();

    useEffect(() => {
        let ignore = false;

        $('#edit-policy-modal').modal({
            backdrop: 'static'
        });

        $('#edit-policy-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal();        
            $('#search-policy-modal').removeClass('modal-blur');       
        }); 
        $('#search-policy-modal').addClass('modal-blur');

        if (!ignore) {

            setSelectedClient({
                ID          : props.policyDetails.ClientID,
                GroupID     : props.policyDetails.ClientGroupID,
                Email       : props.policyDetails.ClientEmail,
                Mobile      : props.policyDetails.ClientMobile,
                Type        : props.policyDetails.Type,
                DisplayName : props.policyDetails.ClientName
            });

            const ext = helper.getFileExt(props.policyDetails.FileName);
            const name = (props.policyDetails.InsuranceCompanyName + ' Policy.'+ ext).replace(/ /g, '_');
            setFileName(name)

            if (props.from === 'Client')
                fetchClientByGroupID();
            else
                fetchClients();
   
            fetchInsuranceCompanies();
            fetchProducts();
            fetchExtraData();
     
        }
          return () => {
            ignore = true;
        };

    }, []);

    useEffect(() => {
        $('[data-toggle="kt-popover"]').popover();
    })  

    useEffect(() => {
        if (policyDetails.ProductID != props.policyDetails.ProductID && policyDetails.PreviousPolicyID == 0) {
            const product = products.find((p) => p.ID == policyDetails.ProductID);
            if (product.ExtraFields === "MotorPolicy") {
                setIsMotorPolicy(true);
                setIsMarinePolicy(false);
                if (!policyDetails.MotorType){
                    updatePolicyDetails('MotorType', "Petrol");
                }            
            }else if (product.ExtraFields === "MarinePolicy") {
                setIsMotorPolicy(false);
                setIsMarinePolicy(true);
                if (!policyDetails.DeclarationBasis){
                updatePolicyDetails('DeclarationBasis', "Monthly");
                } 
            }
            else{
                setIsMotorPolicy(false);
                setIsMarinePolicy(false);
            }
        }

        if (policyDetails.FromDate && policyDetails.ToDate) {
            const diff = moment(policyDetails.ToDate).diff(policyDetails.FromDate, 'days')
            if (diff < 362) {
                setShowRenewalBtn(true)
            } else {
                setShowRenewalBtn(false)
            }
        }

        if (policyDetails) {
            fetchPaymentMode(policyDetails.ID)
        }
    }, [policyDetails]);

    useEffect(() => {
        if (file) {
            setFileName(file.name)
        }
    }, [file]);

    useEffect(() => {

        if (policyDetails.PreviousPolicyID == 0 && clients.length > 0) {
  
            if (addedClientId){
                const client = clients.find(c => c.ID == addedClientId);
                setSelectedClient(client);
                setAddedClientId(null);
            }             
  
            if (addedMemberId){
                const client = clients.find(c => c.ID == addedMemberId);
                setSelectedClient(client);
                setAddedMemberId(null);
            }       
        }  
  
    }, [clients]);

    useEffect(() => {
        if (insuranceCompanies.length > 0 && addedCompanyId) { 
          const company = insuranceCompanies.find(c => c.ID == addedCompanyId);
          changeCompanyHandler({
            label: company.Name,
            value: company.ID
          });  
        }          
    }, [insuranceCompanies]);

    useEffect(() => {
        if (policyDetails.PreviousPolicyID == 0 && products.length > 0 && addedProductId) { 
            const product = products.find(c => c.ID == addedProductId);
            changeProductHandler({
                label: product.Name,
                value: product.ID
            });         
        }        
    }, [products]);

    useEffect(() => {   
        if (policyDetails.PreviousPolicyID == 0 && (addedClientId || addedMemberId))
            fetchClients();        
    }, [addedClientId, addedMemberId]);

    useEffect(() => {       
        if (addedCompanyId)
            fetchInsuranceCompanies();  
    }, [addedCompanyId]);

    useEffect(() => {  
        if (policyDetails.PreviousPolicyID == 0 && addedProductId)
            fetchProducts();        
    }, [addedProductId]);

     // Update GrossPremium when GST is updated
    useEffect(() => {
        if (lastUpdatedField === "GST" && policyDetails.Premium) {
            const calculatedGrossPremium = Math.ceil(
                policyDetails.Premium + (policyDetails.Premium * policyDetails.GST) / 100
            );

            if (calculatedGrossPremium !== policyDetails.GrossPremium) {
                setPolicyDetails((prev) => ({
                    ...prev,
                    GrossPremium: calculatedGrossPremium,
                }));
            }
        }
    }, [policyDetails.Premium, policyDetails.GST, lastUpdatedField]);

    useEffect(() => {
        if (lastUpdatedField === "Premium") {
            const calculatedGrossPremium = Math.ceil(
                policyDetails.Premium + (policyDetails.Premium * policyDetails.GST) / 100
            );

            if (calculatedGrossPremium !== policyDetails.GrossPremium) {
                setPolicyDetails((prev) => ({
                    ...prev,
                    GrossPremium: calculatedGrossPremium,
                }));
            }
        }
    }, [policyDetails.Premium])

    // Update GST when GrossPremium is updated
    useEffect(() => {
        if (lastUpdatedField === "GrossPremium" && policyDetails.Premium) {
            const calculatedGST = ((policyDetails.GrossPremium - policyDetails.Premium) / policyDetails.Premium) * 100;

            if (Number(policyDetails.GST) !== Number(parseInt(calculatedGST))) {
                setPolicyDetails((prev) => ({
                    ...prev,
                    GST: calculatedGST,
                }));
            }
        }
    }, [policyDetails.Premium, policyDetails.GrossPremium, lastUpdatedField]);

    useEffect(() => {
    
        if (isMotorPolicy && (policyDetails.TPPremium || policyDetails.ODPremium || policyDetails.AddOnPremium)) {
            setPolicyDetails((prev) => ({
                ...prev,
                Premium: (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
                : ((policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? parseInt(policyDetails.TPPremium) + parseInt(policyDetails.AddOnPremium) : parseInt(policyDetails.TPPremium),
            }));
            setLastUpdatedField('Premium')
        }
    }, [policyDetails.TPPremium, policyDetails.ODPremium, policyDetails.AddOnPremium])
    
    const updatePolicyDetails = (field, value) => {
        if (field === 'GST' || field === 'GrossPremium' || field === 'Premium') {
            setPolicyDetails((prev) => ({
                ...prev,
                [field]: Number(value) || 0,
            }));
            setLastUpdatedField(field);
        } else {
            setPolicyDetails((prev) => ({
                ...prev,
                [field]: value,
            }));
        }
    };

    const changeCompanyHandler = (e) => {
        updatePolicyDetails('InsuranceCompanyID', e.value);
        updatePolicyDetails('InsuranceCompanyName', e.label);
        setAddedCompanyId(null);
    }

    const changeProductHandler = (e) => {
        updatePolicyDetails('ProductID', e.value);
        updatePolicyDetails('ProductName', e.label);
        setAddedProductId(null);
    }

    const fetchClientByGroupID = async () => {
        try {
            const response = await getClientsByGroupIDAndCompanyID(props.policyDetails.ClientGroupID);
            setClients(
                response.map((c) => ({
                    ...c,
                    DisplayName:
                        c.Type === 'Corporate' && c.Category === 'Primary'
                            ? c.CompanyName
                            : `${c.FirstName} ${c.LastName}`
                }))
                .sort((a, b) =>
                    a.DisplayName > b.DisplayName
                    ? 1
                    : a.DisplayName < b.DisplayName
                    ? -1
                    : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchPaymentMode = async (policyID) => {
        try {
            const response = await getPolicyPaymentModeByPolicyID(policyID);
            setPaymentDetails(response)
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchClients = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getMainClients() : await getMainClientsByAgentID();
            setClients(
                response.map((c) => ({
                    ...c,
                    DisplayName:
                        c.Type === 'Corporate' && c.Category === 'Primary'
                            ? c.CompanyName
                            : `${c.FirstName} ${c.LastName}`
                }))
                .sort((a, b) =>
                    a.DisplayName > b.DisplayName
                    ? 1
                    : a.DisplayName < b.DisplayName
                    ? -1
                    : 0
                )
            );
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchInsuranceCompanies = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
            setInsuranceCompanies(
            response
            .sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )
            ); 
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }    
    };

    const fetchProducts = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getProducts() : await getProductsByAgentID();
            setProducts(
                response
                .sort((a, b) =>
                    a.Name > b.Name
                    ? 1
                    : a.Name < b.Name
                    ? -1
                    : 0
                )
            );     
                
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchExtraData = async () => {
        try {
            if (props.policyDetails.ExtraFields === 'MotorPolicy') {
                const motorPolicy = await getMotorPolicy(props.policyDetails.ID);
                if(motorPolicy) {
                    const { RegistrationNo, Make, Model, Type, ODPremium, TPPremium, AddOnPremium, NCB, EngineNo, ChassisNo } = motorPolicy;
                    if (props.policyDetails.ProductID == 2 || props.policyDetails.ProductID == 4 || props.policyDetails.ProductID == 21) {
                        setPolicyDetails((d) => {
                            return { ...d, RegistrationNo, Make, Model, MotorType: Type, ODPremium, TPPremium, AddOnPremium, NCB, EngineNo, ChassisNo };
                        });
                    } else {
                        setPolicyDetails((d) => {
                            return { ...d, RegistrationNo, Make, Model, MotorType: Type, ODPremium, TPPremium, AddOnPremium, NCB, EngineNo, ChassisNo };
                        });
                    }
                }
                setIsMotorPolicy(true);
                setIsMarinePolicy(false);
            } else if (props.policyDetails.ExtraFields === 'MarinePolicy') {
                const marinePolicy = await getMarinePolicy(props.policyDetails.ID);
                if (marinePolicy) {
                    const { DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder } = marinePolicy;
                    setPolicyDetails((d) => {
                        return { ...d, DeclarationBasis, PerBottomLimit, PerSendingLimit, IsAutoReminder };
                    });
                };
                setIsMarinePolicy(true);
                setIsMotorPolicy(false);
            }
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            }); 
        }
    }

    const setEditorData = (html) => {
        updatePolicyDetails("AdditionalData", html);
    }

    const memberAddHandler = (e) => {
        e.preventDefault();
        $('#edit-policy-modal').addClass('modal-blur');
        $('#add-member-modal').modal('toggle');
    }

    const clientAddHandler = (e) => {
        e.preventDefault();
        $('#edit-policy-modal').addClass('modal-blur');
        props.onOpenModal('client', true, 'policy', '', '', {type: 'directClient'});
    }

    const getClientName = () => {
        const foundClient = clients.find((c) => c.GroupID === selectedClient.GroupID && c.Category === 'Primary');
        if (foundClient)
          return { DisplayName: foundClient.DisplayName,  Type: foundClient.Type};
        else return { DisplayName: '',  Type: ''};
    };

    const openFile = async () => {
        if (file)
            helper.openFileHandler(file);
        else
            helper.downloadAndOpenFileHandler("Policy", props.policyDetails.FileName, fileName)
    }

    const fileHandler = async (f) => {
        setFile(f);
    }

    const removeFileHandler = (e) => {
        e.preventDefault();
        setFile(null);
        setFileName(null)
    }

    const companyAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Company');   
        $('#edit-policy-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };
    
    const productAddHandler = (e) => {
        e.preventDefault();
        setAddModalType('Product');
        $('#edit-policy-modal').addClass('modal-blur');
        $('#add-company-modal').modal('toggle');
    };

    const editPolicyHandler = async (e) => {
        e.preventDefault();

        var form = $('#edit-policy-form');
        form.validate().destroy();
      
        form.validate({
          rules: {
            ddlClient: {
              required: true,
            },
            ddlInsuranceCompany: {
              required: true,
            },
            ddlProduct: {
              required: true,
            },
            policyNo: {
              required: true,
            },
            sumInsured: {
              required: true,
            },
            premium: {
              required: true,
            },
            tpPremium: {
                required: true,
            },
            addOnPremium: {
                required: true,
            },
            fromDate: {
              required: true,
            },
            toDate: {
              required: true,
            },
            issueDate: {
              required: true,
            },
            registrationNo: {
              required: true,
            },
            make: {
              required: true,
            },
            model: {
              required: true,
            },
          },
        });
      
        if (!form.valid()) {
          return;
        }

        try {
            setBtnLoading(true);

            if (!fileName) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Please upload a policy",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',
                });
                setBtnLoading(false);
                return;
            }

            let newFileName = '';

            if (file) 
                newFileName = await uploadFile(file, "Policy")

            await updatePolicy({
                ID                    : policyDetails.ID,
                GroupID               : policyDetails.GroupID,
                InsuranceCompanyID    : policyDetails.InsuranceCompanyID,
                ProductID             : policyDetails.ProductID,
                ClientID              : selectedClient.ID,
                PolicyNo              : policyDetails.PolicyNo,
                BusinessType          : businessType,
                IssueDate             : policyDetails.IssueDate,
                FromDate              : policyDetails.FromDate,
                ToDate                : policyDetails.ToDate,
                Premium               : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) + parseInt(policyDetails.TPPremium) 
                                      : (isMotorPolicy && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? parseInt(policyDetails.ODPremium) + parseInt(policyDetails.AddOnPremium) : (isMotorPolicy) ? parseInt(policyDetails.TPPremium) : parseInt(policyDetails.Premium),
                GrossPremium          : parseInt(policyDetails.GrossPremium),
                GST                   : policyDetails.GST,
                SumInsured            : parseInt(policyDetails.SumInsured),
                AdditionalData        : policyDetails.AdditionalData || '',
                SendReminder          : policyDetails.SendReminder,
                FileName              : file ? newFileName : policyDetails.FileName
            }, paymentDetails);
    
            if (isMotorPolicy) {
                await postMotorPolicy({
                    PolicyID          : policyDetails.ID,
                    RegistrationNo    : policyDetails.RegistrationNo,
                    EngineNo          : policyDetails.EngineNo,
                    ChassisNo          : policyDetails.ChassisNo,
                    Make              : policyDetails.Make,
                    Model             : policyDetails.Model,
                    Type              : policyDetails.MotorType || 'Petrol',
                    ODPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.ODPremium),
                    TPPremium         : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? parseInt(policyDetails.TPPremium)
                                      : (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) ? parseInt(policyDetails.TPPremium) : 0,
                    AddOnPremium      : (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21) ? 0 : parseInt(policyDetails.AddOnPremium),
                    NCB               : policyDetails.NCB,
                });
            } else if (isMarinePolicy) {
                await postMarinePolicy({
                    PolicyID          : policyDetails.ID,
                    DeclarationBasis  : policyDetails.DeclarationBasis,
                    PerBottomLimit    : parseInt(policyDetails.PerBottomLimit),
                    PerSendingLimit   : parseInt(policyDetails.PerSendingLimit),
                    IsAutoReminder    : "N",
                });
            }
            setBtnLoading(false);
            let client = {}

            if (selectedClient.ID != props.policyDetails.ClientID) {
                client = {
                    Type: selectedClient.Type,
                    ClientCompanyName : selectedClient.Type === 'Corporate' ? selectedClient.CompanyName : '',
                    ClientEmail : selectedClient.Email ? selectedClient.Email : '',
                    ClientGroupID : selectedClient.GroupID,
                    ClientID : selectedClient.ID,
                    ClientMobile : selectedClient.Mobile ? selectedClient.Mobile : '',
                    ClientName : selectedClient.FirstName + selectedClient.LastName,
                    DisplayName : selectedClient.FirstName + selectedClient.LastName,
                    PrimaryClientName : selectedClient.Type === 'Corporate' ? selectedClient.CompanyName : '',
                    PrimaryClientEmail : '',
                    PrimaryClientMobile : ''
                }
            }
            if (props.policyDetails.From === 'policyHistory')
                props.refetchPolicyHistory({...policyDetails, ...client});
            else
                props.refetchPolicies();
                // props.refetchPolicies({...policyDetails, 
                    // Type: selectedClient.Type,
                    // ClientCompanyName : selectedClient.Type === 'Corporate' ? selectedClient.CompanyName : '',
                    // ClientEmail : selectedClient.Email ? selectedClient.Email : '',
                    // ClientGroupID : selectedClient.GroupID,
                    // ClientID : selectedClient.ID,
                    // ClientMobile : selectedClient.Mobile ? selectedClient.Mobile : '',
                    // ClientName : selectedClient.FirstName + selectedClient.LastName,
                    // DisplayName : selectedClient.FirstName + selectedClient.LastName,
                    // PrimaryClientName : selectedClient.Type === 'Corporate' ? selectedClient.CompanyName : '',
                    // PrimaryClientEmail : '',
                    // PrimaryClientMobile : ''
                // });
            $('#edit-policy-modal').modal('hide');
            swal.fire({
                icon              : 'success',
                titleText         : 'Policy Updated Successfully',
                showConfirmButton : false,
                timer             : 1500,
            });
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            });
            setBtnLoading(false);
            return;
        }
    };

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="edit-policy-modal"
                tabIndex="-1"
                role="dialog"
                aria-hidden="true">
            
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Policy</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                disabled={btnLoading}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form id="edit-policy-form" className="kt-form" onSubmit={(e) => e.preventDefault()}>
                                {policyDetails.PreviousPolicyID != 0 ? (
                                    <div className='kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded'>
                                        <table className='kt-datatable__table table-striped'>
                                            <tbody className='kt-datatable__body' style={{ display: 'block' }}>
                                                <tr className='kt-datatable__row block-row'>
                                                    <td className="kt-datatable__cell text-clip" width="35%">
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__pic">
                                                            <img src={policyDetails.ProductIcon} alt="product"/>
                                                            </div>
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__name" title={policyDetails.PolicyNo}>{policyDetails.PolicyNo}</span>
                                                                <span className="kt-user-card-v2__desc">
                                                                    <span title={policyDetails.InsuranceCompanyName}>{policyDetails.InsuranceCompanyName}</span><br/>
                                                                    <span title={policyDetails.ProductName}>{policyDetails.ProductName}</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={policyDetails.ClientName}>
                                                        <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__desc">Insured Name:</span>
                                                                <span className="kt-user-card-v2__name">{policyDetails.ClientName}</span>
                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={moment(policyDetails.ToDate).format('DD-MMM-YYYY')}>
                                                            <div className="kt-user-card-v2">
                                                            <div className="kt-user-card-v2__details">
                                                                <span className="kt-user-card-v2__desc">End Date:</span>
                                                                <span className="kt-user-card-v2__name">{moment(policyDetails.ToDate).format('DD/MM/YYYY')}</span>
                                                
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="kt-datatable__cell text-clip" title={policyDetails.Status}>
                                                        <div className={`policy-status block-badge mr-1 badge badge-${policyDetails.Status === 'Renewal Due' ? 'secondary' : (policyDetails.Status === 'Expired' ? 'label-danger' : 'warning')}`}>
                                                            <span>{policyDetails.Status}</span>
                                                        </div>
                                                    </td> 
                                                </tr>                                                             
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                <label htmlFor="ddlBusinessType">Business Type</label>
                                                    <select
                                                        className="form-control kt-selectpicker"
                                                        id="ddlBusinessType"
                                                        value={businessType || 'New'}
                                                        onChange={(event) => setBusinessType(event.target.value)}>                                                 
                                                        <option value="New">New</option>
                                                        <option value="Renewal">Renewal</option>
                                                        <option value="Rollover">Rollover</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-9">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="form-group">
                                                            <label htmlFor="ddlClient">Client</label>
                                                            <Select
                                                                value={selectedClient && {label: selectedClient.DisplayName, value: selectedClient}}
                                                                onChange={(event) => setSelectedClient(event.value)}
                                                                name="ddlClient"
                                                                placeholder="Select Client"
                                                                id="ddlClient"
                                                                className="ddlClient"
                                                                options={clients.map(c => ({
                                                                    label: c.DisplayName,
                                                                    value: c
                                                                }))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        {(selectedClient || props.from === 'Client') ? (
                                                            <button
                                                                id="btnNewMember"
                                                                className="btn btn-label-brand btn-block margin-0 margin-md-t24"
                                                                onClick={memberAddHandler}>                          
                                                                <i className="la la-plus" />
                                                                New Member
                                                            </button>
                                                            ) : (
                                                            <button
                                                                id="btnNewClient"
                                                                className="btn btn-brand btn-block margin-0 margin-md-t24"
                                                                onClick={clientAddHandler}>                                           
                                                                <i className="la la-plus" />
                                                                New Client
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {selectedClient && (
                                            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded">
                                                <table className="kt-datatable__table table-striped">
                                                    <tbody className="kt-datatable__body" style={{ display: 'block' }}>
                                                        <tr className="kt-datatable__row block-row">
                                                            <td className="kt-datatable__cell text-clip">
                                                                <div className="kt-user-card-v2">
                                                                    <div className="kt-user-card-v2__pic">
                                                                        <div className={`kt-badge kt-badge--xl kt-badge--${ selectedClient.Type === 'Corporate' ? 'success' : 'info' }`}>                                                                                 
                                                                            {selectedClient.DisplayName.slice(0, 1)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="kt-user-card-v2__details">
                                                                        <span className="kt-user-card-v2__name">{selectedClient.DisplayName}</span>
                                                                        <span className="kt-user-card-v2__desc">{selectedClient.Type === 'Corporate' ? 'Organization' : selectedClient.Type}</span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="kt-datatable__cell text-clip">
                                                                <span>
                                                                    {selectedClient.Email || ''}
                                                                    {selectedClient.Mobile ? `${selectedClient.Email ? ` | ${selectedClient.Mobile}` : selectedClient.Mobile}` : ''}
                                                                </span>
                                                            </td>
                                                            <td className="text-right padding-r20">
                                                                <button 
                                                                    type="button"                    
                                                                    onClick={(e) => setSelectedClient(null)}
                                                                    className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">     
                                                                    <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                                </button>                           
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}

                                {(selectedClient && !fileName) && (<Dropzone fileHandler={fileHandler} prompt="Drop policy file here or click to select"/>)}
                                {fileName && (
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="kt-notification margin-b30" style={{backgroundColor: '#f7f8fa'}}>
                                                    <a className="kt-notification__item">
                                                        <div className="kt-notification__item-icon margin-r10" onClick={openFile}>
                                                          <img height="30px" width="30px" src={helper.getFileLogo(fileName)} />
                                                        </div>
                                                        <div className="kt-notification__item-details" onClick={openFile}>
                                                            <div className="kt-notification__item-title kt-font-bold">
                                                                {fileName}
                                                            </div>
                                                        </div>
                                                        <button 
                                                            type="button"                    
                                                            onClick={removeFileHandler}
                                                            className="btn btn-outline-info btn-elevate btn-circle btn-icon btn-xs margin-r10">
                                                            <i className="flaticon2-rubbish-bin-delete-button"></i>
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> 
                                    </React.Fragment>
                                )}
                                <div className="row">
                                    <div className={`${policyDetails.PreviousPolicyID != 0 ? 'col-lg-12' : 'col-lg-6'}`}>
                                        <div className="row">
                                            <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="ddlInsuranceCompany">Insurance Company</label>       
                                                    <Select
                                                        value={policyDetails && policyDetails.InsuranceCompanyID && {label: policyDetails.InsuranceCompanyName, value: policyDetails.InsuranceCompanyID}}
                                                        onChange={changeCompanyHandler}
                                                        name="ddlInsuranceCompany"
                                                        id="ddlInsuranceCompany"
                                                        placeholder="Select Company"
                                                        options={insuranceCompanies.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            {userDetails.user.categoryId != 3 && (
                                                <div className="col-4">
                                                    <button
                                                        id="btnNewCompany"
                                                        className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="bottom"
                                                        data-content="Add Insurance Company"
                                                        onClick={companyAddHandler}>
                                                        <i className="la la-plus" />
                                                        New
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {policyDetails.PreviousPolicyID == 0 && (<div className="col-lg-6">
                                        <div className="row">
                                            <div className={`${userDetails.user.categoryId != 3 ? 'col-8' : 'col-12'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="ddlProduct">Product</label>
                                                    <Select
                                                        value={policyDetails && policyDetails.ProductID && {label: policyDetails.ProductName, value: policyDetails.ProductID}}
                                                        onChange={changeProductHandler}
                                                        name="ddlProduct"
                                                        id="ddlProduct"
                                                        placeholder="Select Product"
                                                        options={products.map(c => ({
                                                            label: c.Name,
                                                            value: c.ID
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                            {userDetails.user.categoryId != 3 && (
                                                <div className="col-4">
                                                    <button
                                                        id="btnNewProduct"
                                                        className="btn btn-label-brand btn-block margin-0 margin-t24"
                                                        data-toggle="kt-popover"
                                                        data-trigger="hover"
                                                        data-placement="bottom"
                                                        data-content="Add Product"
                                                        onClick={productAddHandler}>           
                                                        <i className="la la-plus" />
                                                        New
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>)}
                                </div>
                                <div className="row">
                                    <div className={`${isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 1 && policyDetails.ProductID != 3 && policyDetails.ProductID != 20 && policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="policyNo">Policy No.</label>
                                            <input
                                                id="policyNo"
                                                value={policyDetails.PolicyNo || ''}
                                                name="policyNo"
                                                className="form-control margin-b10"
                                                type="text"
                                                onChange={(event) => updatePolicyDetails('PolicyNo', event.target.value)}               
                                            />                     
                                        </div>
                                    </div>
                                    <div className={`${isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2' : 'col-md-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="sumInsured">Sum Insured</label>
                                            <input
                                                id="sumInsured"
                                                value={policyDetails.SumInsured || ''}
                                                name="sumInsured"
                                                className="form-control margin-b10"
                                                type="number"
                                                onChange={(event) => updatePolicyDetails('SumInsured', event.target.value)}                                                   
                                            />
                                        </div>
                                    </div>
                                    {!isMotorPolicy && (
                                        <div className="col-md-4 margin-b0">
                                            <div className="form-group">
                                                <label htmlFor="premium">Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                                                <input
                                                    id="premium"
                                                    value={policyDetails.Premium || ''}
                                                    name="premium"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    onChange={(event) => updatePolicyDetails('Premium', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 2 || policyDetails.ProductID == 4 || policyDetails.ProductID == 21)) && (
                                        <div className="col-md-4 margin-b0">
                                            <div className="form-group">
                                                <label htmlFor="premium">TP Premium <span style={{'fontsize': '8px'}} >(Excluding GST)</span></label>
                                                <input
                                                    id="premium"
                                                    value={policyDetails.TPPremium || ''}
                                                    name="premium"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    onChange={(event) => updatePolicyDetails('TPPremium', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {isMotorPolicy && (policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                                        <>
                                            <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20)) ? 'col-md-2 ' : 'col-md-3'} margin-b0`}>
                                                <div className="form-group">
                                                    <label htmlFor="premium">OD Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                    <input
                                                        id="premium"
                                                        value={policyDetails.ODPremium || ''}
                                                        name="premium"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('ODPremium', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2 margin-b0">
                                                <div className="form-group">
                                                    <label htmlFor="addOnPremium">Add On Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                    <input
                                                        id="addOnPremium"
                                                        // value={policyDetails.AddOnPremium || ''}
                                                        value={policyDetails.AddOnPremium || policyDetails.AddOnPremium === 0 ? policyDetails.AddOnPremium : ''}
                                                        name="addOnPremium"
                                                        className="form-control margin-b10"
                                                        type="number"
                                                        onChange={(event) => updatePolicyDetails('AddOnPremium', event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID == 1 || policyDetails.ProductID == 3 || policyDetails.ProductID == 20) && (
                                                <div className="col-md-2 margin-b0">
                                                    <div className="form-group">
                                                        <label htmlFor="tpPremium">TP Premium <span style={{'fontSize': '11px'}} ></span></label>
                                                        <input
                                                            id="tpPremium"
                                                            // value={policyDetails.TPPremium || ''}
                                                            value={policyDetails.TPPremium || policyDetails.TPPremium === 0 ? policyDetails.TPPremium : ''}
                                                            name="tpPremium"
                                                            className="form-control margin-b10"
                                                            type="number"
                                                            onChange={(event) => updatePolicyDetails('TPPremium', event.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <label htmlFor="gst">GST</label>
                                        <div className='input-group'>
                                            <input
                                                id="gst"
                                                value={policyDetails.GST}
                                                name="gst"
                                                className="form-control"
                                                max='100'
                                                type="number"
                                                onChange={(event) => updatePolicyDetails('GST', event.target.value)}                                                   
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${'col-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="GrossPremium">Gross Premium</label>
                                            <input
                                            id="GrossPremium"
                                            value={policyDetails.GrossPremium}
                                            name="GrossPremium"
                                            className="form-control margin-b10"
                                            type="number"
                                            onChange={(event) => updatePolicyDetails('GrossPremium', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button
                                            id="btnNewProduct"
                                            className="btn btn-label-brand btn-block margin-0 margin-t24"
                                            data-toggle="kt-popover"
                                            data-trigger="hover"
                                            data-placement="bottom"
                                            data-content={`${paymentDetails.PaymentType != 0 ? 'Update' : 'Add'} Payment Mode`}
                                            onClick={() => setShowPaymentModal(true)}  
                                        >         
                                            <i className="la la-plus" />
                                            {`${paymentDetails.PaymentType != 0 ? 'Update' : 'Add'} Payment Mode`}
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="fromDate">Start Date</label>
                                            <input
                                                id="fromDate"
                                                value={moment(policyDetails.FromDate).format('YYYY-MM-DD') || ''}
                                                name="fromDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updatePolicyDetails('FromDate', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="toDate">End Date</label>
                                            <input
                                                id="toDate"
                                                value={moment(policyDetails.ToDate).format('YYYY-MM-DD') || ''}
                                                name="toDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updatePolicyDetails('ToDate', event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={`${showRenewalBtn ? 'col-md-3' : 'col-md-4'} margin-b0`}>
                                        <div className="form-group">
                                            <label htmlFor="issueDate">Issued On</label>
                                            <input
                                                id="issueDate"
                                                value={moment(policyDetails.IssueDate).format('YYYY-MM-DD') || ''}
                                                name="issueDate"
                                                className="form-control margin-b10"
                                                type="date"
                                                onChange={(event) => updatePolicyDetails('IssueDate', event.target.value)}                                            
                                            />
                                        </div>
                                    </div>
                                    {showRenewalBtn && (
                                        <div className="col-md-3 margin-b0">
                                            <div className="form-group">
                                                <label htmlFor="issueDate">Send Renewal Reminder</label>
                                                <span className="kt-switch kt-switch--icon">
                                                    <label>  
                                                        <input 
                                                            id="chkActive" 
                                                            name="chkActive" 
                                                            type="checkbox"
                                                            defaultChecked={policyDetails.SendReminder && policyDetails.SendReminder == 'N' ? '' : 'checked'}
                                                            onChange={(event) => updatePolicyDetails('SendReminder', event.target.checked ? 'Y' : 'N')} 
                                                        />
                                                        <span></span> 
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {(isMotorPolicy) && (
                                    <>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="registrationNo">Registration No.</label>
                                                    <input
                                                        id="registrationNo"
                                                        value={policyDetails.RegistrationNo || ''}
                                                        name="registrationNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('RegistrationNo', event.target.value)}               
                                                    />                     
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label htmlFor="make">Make</label>
                                                        <input
                                                            id="make"
                                                            value={policyDetails.Make || ''}
                                                            name="make"
                                                            className="form-control margin-b10"
                                                            type="text"
                                                            readOnly={policyDetails.MakeEditable}
                                                            onChange={(event) => updatePolicyDetails('Make', event.target.value)}   
                                                        />                     
                                                    </div>
                                            </div>
                                            <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="model">Model</label>
                                                    <input
                                                        id="model"
                                                        value={policyDetails.Model || ''}
                                                        name="model"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        readOnly={policyDetails.ModelEditable}
                                                        onChange={(event) => updatePolicyDetails('Model', event.target.value)}  
                                                    />                     
                                                </div>
                                            </div>
                                            <div className={`${(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) ? 'col-md-2' : 'col-md-3'}`}>
                                                <div className="form-group">
                                                    <label htmlFor="ddlType">Type</label>
                                                    <select
                                                        className="form-control kt-selectpicker"
                                                        id="ddlType"
                                                        readOnly={policyDetails.TypeEditable}
                                                        disabled={policyDetails.TypeEditable}
                                                        value={policyDetails.MotorType || 'Petrol'}
                                                        onChange={(event) => updatePolicyDetails('MotorType', event.target.value)}>                                            
                                                        <option value="Petrol">Petrol</option>
                                                        <option value="Diesel">Diesel</option>
                                                        <option value="Electric">Electric</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {(policyDetails && policyDetails.ProductID && (policyDetails.ProductID != 2 && policyDetails.ProductID != 4 && policyDetails.ProductID != 21)) && (
                                                <div className='col-md-2'>
                                                    <div className="form-group">
                                                        <label htmlFor="ncb">NCB</label>
                                                        <div className='input-group'>
                                                            <input
                                                                name="ncb"
                                                                id="ncb"
                                                                value={policyDetails.NCB}
                                                                max='100'
                                                                className="form-control"
                                                                type="number"
                                                                onChange={(event) => updatePolicyDetails('NCB', event.target.value)}                                                   
                                                            />
                                                            <div className="input-group-append">
                                                            <span className="input-group-text">%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="EngineNo">Engine No.</label>
                                                    <input
                                                        id="engineNo"
                                                        value={policyDetails.EngineNo || ''}
                                                        name="engineNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('EngineNo', event.target.value)}               
                                                    />                     
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="ChassisNo">Chassis No.</label>
                                                    <input
                                                        id="chassisNo"
                                                        value={policyDetails.ChassisNo || ''}
                                                        name="chassisNo"
                                                        className="form-control margin-b10"
                                                        type="text"
                                                        onChange={(event) => updatePolicyDetails('ChassisNo', event.target.value)}               
                                                    />                     
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {(isMarinePolicy) && (
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="ddlDeclarationBasis">Declaration Basis</label>
                                                <select
                                                    className="form-control kt-selectpicker"
                                                    id="ddlDeclarationBasis"
                                                    value={policyDetails.DeclarationBasis}
                                                    onChange={(event) => updatePolicyDetails('DeclarationBasis', event.target.value)}>                                                 
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Certificate Issuance">Certificate Issuance</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="perBottomLimit">Per Bottom Limit</label>
                                                <input
                                                    id="perBottomLimit"
                                                    value={policyDetails.PerBottomLimit || ''}
                                                    name="perBottomLimit"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    onChange={(event) => updatePolicyDetails('PerBottomLimit', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="perBottomLimit">Per Sending Limit</label>
                                                <input
                                                    id="perSendingLimit"
                                                    value={policyDetails.PerSendingLimit || ''}
                                                    name="perSendingLimit"
                                                    className="form-control margin-b10"
                                                    type="number"
                                                    onChange={(event) => updatePolicyDetails('PerSendingLimit', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-12 margin-b20">
                                        <CustomEditor
                                            placeholder="Additional Details"
                                            addQuickText={setAddQuickText}
                                            fillQuickText={fillQuickText}
                                            setFillQuickText={setFillQuickText}
                                            setData={setEditorData}
                                        />     
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary margin-0 margin-r5"
                                data-dismiss="modal"
                                disabled={btnLoading}>
                                Close
                            </button>
                            <button
                                id="save-btn"
                                onClick={editPolicyHandler}
                                type="button"
                                className={`btn btn-brand margin-0 ${
                                btnLoading
                                    ? 'kt-spinner kt-spinner--sm kt-spinner--light'
                                    : ''
                                }`}
                                disabled={btnLoading} >                  
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {selectedClient && (
                <AddMember
                    addMemberId={setAddedMemberId}
                    groupId={selectedClient.GroupID}
                    client={getClientName()}
                    from="#edit-policy-modal"
                />
            )}
            {showPaymentModal && (
                <PolicyPaymentModal
                    from="#edit-policy-modal"
                    setPaymentDetails={setPaymentDetails}
                    paymentDetails={paymentDetails}
                    onDismissModal={() => setShowPaymentModal(false)}
                />
            )}
            {(fileName || !fileName) && (
                <AddCompany
                    type={addModalType}
                    addInsuranceCompanyId={setAddedCompanyId}
                    addProductId={setAddedProductId}
                    from="#edit-policy-modal"
                />
            )}
            {addQuickText && (
                <AddQuickText
                    fillQuickText={setFillQuickText}
                    addQuickText={setAddQuickText}
                    from="#edit-policy-modal"
                />
            )}
        </React.Fragment>
    )

}

export default EditPolicy