import React, { useEffect, useState } from 'react';
import '../../containers/App.css'
import { getAllIncentiveTarget, getBusinessReports, getBusinessReportsByAgentID, getBusinessReportsForMotor, getInsuranceCompanies, getInsuranceCompaniesByAgentID, getPolicyCommissionReports, getPolicyCommissionReportsForMotor, getProducts, getProductsByAgentID, getRevenueMaster } from '../api/policyAPI';
import { prevPeriods } from '../../assets/scripts/periodSelection';
import { getClients, getMainClients, getMainClientsByAgentID } from '../api/clientAPI';
import Select from 'react-select';
import Loading from '../global/loading';
import ReportImg from '../../assets/images/no-report.svg'
import NoData from '../global/noData';
import * as helper from '../global/helper';
import swal from 'sweetalert2';
import { getAuthState } from '../../assets/scripts/login-util';
import PolicyCommissionModal from './PolicyCommissionModal';

const PolicyReport = (props) => {

    const [businessReports,         setBusinessReports]         = useState([]);
    const [revenueMaster,           setRevenueMaster]           = useState([]); 
    const [targetDetails,           setTargetDetails]           = useState([]); 
    const [revenueBusiness,         setRevnenueBusiness]        = useState([]);
    const [loading,                 setLoading]                 = useState(false);
    const [currentPeriod,           setCurrentPeriod]           = useState(prevPeriods[0]);
    const [fromDate,                setFromDate]                = useState(prevPeriods[0].from);
    const [toDate,                  setToDate]                  = useState(prevPeriods[0].to);
    const [clients,                 setClients]                 = useState([]);
    const [insuranceCompanies,      setInsuranceCompanies]      = useState([]);
    const [products,                setProducts]                = useState([]);
    const [searchString,            setSearchString]            = useState('');
    const [filteredBusinessReports, setFilteredBusinessReports] = useState([]);
    const [currentClient,           setCurrentClient]           = useState(null);
    const [currentInsuranceCompany, setCurrentInsuranceCompany] = useState(null);
    const [currentProduct,          setCurrentProduct]          = useState(null);
    const [productType,             setProductType]             = useState('Other Product');
    const [allProduct,              setAllProduct]              = useState([]);
    const [editPolicyCommission,    setEditPolicyCommission]    = useState(null);

    const userDetails = getAuthState();

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            fetchPolicyCommissionReports();
            fetchClients();
            fetchInsuranceCompanies();
            fetchProducts();
        }
        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        helper.SetScrollHeight();
    });

    useEffect(() => {
        if (revenueBusiness.length > 0)
            filterBusinessReports();
        else
            setFilteredBusinessReports([]);
            
    }, [revenueBusiness, searchString, currentInsuranceCompany, currentProduct, currentClient, productType]);
    
    
    useEffect(() => {
        if (productType) fetchPolicyCommissionReports()
    }, [productType])

    useEffect(() => {
        if (currentPeriod.id != 6)
            fetchPolicyCommissionReports()
    }, [fromDate, toDate]);
    

    useEffect(() => {
        if (allProduct && allProduct.length > 0) {
            if (productType == 'Other Product') {
                const filteredProduct = allProduct.filter(a => a.ExtraFields != "MotorPolicy")
                setProducts([{
                    ID: 0, AgentCompanyID: 0, Name: 'All', FullName: 'All'
                }, ...filteredProduct])
            } else {
                const filteredProduct = allProduct.filter(a => a.ExtraFields == "MotorPolicy")
                setProducts([{
                    ID: 0, AgentCompanyID: 0, Name: 'All', FullName: 'All'
                }, ...filteredProduct])
            }
        }
    }, [productType, allProduct])

    useEffect(() => {
        if (currentPeriod.id != 6) {
            setFromDate(currentPeriod.from);
            setToDate(currentPeriod.to);
        }
    }, [currentPeriod]);

    useEffect(() => {
        if (insuranceCompanies && insuranceCompanies.length > 0)
            setCurrentInsuranceCompany(insuranceCompanies[0]);
    }, [insuranceCompanies]);

    useEffect(() => {
        if (products && products.length > 0)
            setCurrentProduct(products[0]);
    }, [products]);

    useEffect(() => {
        if (clients && clients.length > 0)
            setCurrentClient(clients[0]);
    }, [clients]);

    const fetchPolicyCommissionReports = async () => {
        try {
            setLoading(true);

            if (!fromDate || !toDate) {
                swal.fire({
                    icon                  : 'error',
                    titleText             : 'Error!',
                    text                  : "Invalid Date",
                    buttonsStyling        : false,
                    confirmButtonClass    : 'btn btn-brand',   
                });
                setLoading(false);
                return;
            }

            let response = [];

            if (userDetails.user.categoryId != 3) {
                if (productType == 'Other Product') {
                    response = await getPolicyCommissionReports(fromDate, toDate);
                } else {
                    response = await getPolicyCommissionReportsForMotor(fromDate, toDate);
                }
            }
            setRevnenueBusiness(response);
            setLoading(false);  
        } catch (err) {
            setLoading(false);
            swal.close();
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };
    
    const fetchClients = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getMainClients() : await getMainClientsByAgentID();

            setClients([{
                ID: 0, DisplayName: 'All'
            }, ...response.map((c) => ({
                ...c,
                DisplayName:
                c.Type === 'Corporate' && c.Category === 'Primary'
                    ? c.CompanyName
                    : `${c.FirstName} ${c.MiddleName} ${c.LastName}`
            }))
            .sort((a, b) =>
                a.DisplayName > b.DisplayName
                ? 1
                : a.DisplayName < b.DisplayName
                ? -1
                : 0
            )
            ]);
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }
    };

    const fetchInsuranceCompanies = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getInsuranceCompanies() : await getInsuranceCompaniesByAgentID();
            setInsuranceCompanies([{
                ID: 0, AgentCompanyID: 0, Name: 'All', FullName: 'All'
            }, ...response.sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )]);  
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });  
        } 
    };

    const fetchProducts = async () => {
        try {
            const response = userDetails.user.categoryId != 3 ? await getProducts() : await getProductsByAgentID();

            setAllProduct([...response.sort((a, b) =>
                a.Name > b.Name
                ? 1
                : a.Name < b.Name
                ? -1
                : 0
            )]); 
        } catch (err) {
            swal.fire({
                icon                : 'error',
                titleText           : 'Error!',
                text                : err.message,
                buttonsStyling      : false,
                confirmButtonClass  : 'btn btn-brand',
            });
        }  
    };

    const changePeriodHandler = ({ target }) => {
        const period = prevPeriods.find(p => p.id == target.value)
        setCurrentPeriod(period);
    };
    
    const filterBusinessReports = async () => {

        let reports = revenueBusiness;

        if (currentInsuranceCompany && currentInsuranceCompany.ID != 0) reports = reports.filter(f => f.InsuranceCompanyID == currentInsuranceCompany.ID);
        if (productType == 'Other Product') {
            reports = reports.filter(f => f.ExtraFields != 'MotorPolicy');
        } else {
            reports = reports.filter(f => f.ExtraFields == 'MotorPolicy');
        }
        if (currentProduct && currentProduct.ID != 0) reports = reports.filter(f => f.ProductID == currentProduct.ID && f.ExtraFields != 'MotorPolicy');
        if (currentClient && currentClient.ID != 0) reports = reports.filter(f => f.ClientID == currentClient.ID);

        if (searchString === '') {
            setFilteredBusinessReports(reports);
        }else{
            const matchedPolicies = reports.filter((p) => {
                let matchString = `${p.PolicyNo} ${p.PrimaryClientName} ${p.ClientName} ${p.ClientEmail} ${p.PrimaryClientEmail} ${p.ClientCompanyName} ${p.ProductName} ${p.InsuranceCompanyName} ${p.BusinessType} ${p.Premium} ${p.SumInsured}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredBusinessReports(matchedPolicies);
        }
    };

    const calculateCommission = () => {
        const ODCommission = filteredBusinessReports.map(f => f.ODCommission).reduce((prev, curr) => prev + curr, 0)
        const AOCommission = filteredBusinessReports.map(f => f.AOCommission).reduce((prev, curr) => prev + curr, 0)
        const TPCommission = filteredBusinessReports.map(f => f.TPCommission).reduce((prev, curr) => prev + curr, 0)
        return Math.ceil(ODCommission + AOCommission + TPCommission)
    }

    const calculateTotalPayout = (commission, tds) => {
        const percent = (commission * tds) / 100;
        return Math.ceil(commission - percent)
    }

    const calculateTotalPayoutForMotor = (ODCommission, AOCommission, TPCommission, tds) => {
        const totalCommission = parseFloat(ODCommission) + parseFloat(AOCommission) + parseFloat(TPCommission);
        const percent = (totalCommission * tds) / 100;
        return Math.ceil(totalCommission - percent)

    }

    return (
        <>
            <div className="kt-form kt-form--label-right">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row">
                            <div className={`col-md-${currentPeriod.id == 6 ? '4' : '2'} kt-margin-b-20-tablet-and-mobile`}>
                                <div className="kt-form__group form-group margin-b0">
                                    <label htmlFor="ddlPeriod">Periods</label>
                                    <div className="kt-form__control">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlPeriod"
                                            value={currentPeriod.id}
                                            onChange={changePeriodHandler}>
                                            {prevPeriods.map((s) => (
                                                <option value={s.id} key={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {currentPeriod.id == 6 && (
                                <div className="col-md-8 kt-margin-b-20-tablet-and-mobile">
                                    <div className="row align-items-center">
                                        <div className="col-md-10">
                                            <div className="row">
                                                <div className="col-md-6 margin-sm-b20 padding-lr0">
                                                    <div className="kt-form__group form-group margin-b0">
                                                        <label htmlFor="fromDate">From</label>
                                                        <div className="kt-form__control kt-form__group--inline">
                                                            <input
                                                                id="fromDate"
                                                                value={fromDate || ''}
                                                                max={moment().format('YYYY-MM-DD')}
                                                                name="fromDate"
                                                                className="form-control"
                                                                type="date"
                                                                onChange={(event) => setFromDate(event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 margin-sm-b20 padding-lr0">
                                                    <div className="kt-form__group form-group margin-l10 margin-b0">
                                                        <label htmlFor="toDate">To</label>
                                                        <div className="kt-form__control kt-form__group--inline">
                                                            <input
                                                                id="toDate"
                                                                value={toDate || ''}
                                                                max={moment().format('YYYY-MM-DD')}
                                                                name="toDate"
                                                                className="form-control"
                                                                type="date"
                                                                onChange={(event) => setToDate(event.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 text-center margin-t25">
                                            <button className="btn btn-brand btn-block padding-8 margin-0" onClick={() => fetchPolicyCommissionReports()}>
                                                <i className="la la-search"></i>
                                            </button>
                                        </div>
                                    </div>                                   
                                </div>
                            )}
                            <div className="col-md-2">
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlInsuranceCompany">Insurance Company</label>
                                    <Select
                                        value={currentInsuranceCompany && {label: currentInsuranceCompany.Name, value: currentInsuranceCompany}}
                                        onChange={(event) => setCurrentInsuranceCompany(event.value)}
                                        name="ddlInsuranceCompany"
                                        id="ddlInsuranceCompany"
                                        className="ddlInsuranceCompany"
                                        options={insuranceCompanies.map(c => ({
                                            label: c.Name,
                                            value: c
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlProduct">Product Type</label>
                                    <div className="kt-form__control">
                                        <select
                                            className="form-control kt-selectpicker"
                                            id="ddlPeriod"
                                            value={productType}
                                            onChange={({ target }) => setProductType(target.value)}>
                                            <option value="Other Product">Other Product</option>
                                            <option value="Motor">Motor</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlProduct">Product</label>
                                    <Select
                                        value={currentProduct && {label: currentProduct.Name, value: currentProduct}}
                                        onChange={(event) => setCurrentProduct(event.value)}
                                        name="ddlProduct"
                                        id="ddlProduct"
                                        className="ddlProduct"
                                        options={products.map(c => ({
                                            label: c.Name,
                                            value: c
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className={`col-md-${currentPeriod.id == 6 ? '3' : '2'} kt-margin-b-20-tablet-and-mobile`}>
                                <div className="form-group margin-b0">
                                    <label htmlFor="ddlClient">Client</label>
                                    <Select
                                        value={currentClient && {label: currentClient.DisplayName, value: currentClient}}
                                        onChange={(event) => setCurrentClient(event.value)}
                                        name="ddlClient"
                                        id="ddlClient"
                                        className="ddlClient"
                                        options={clients.map(c => ({
                                            label: c.DisplayName,
                                            value: c
                                        }))}
                                    />
                                </div>
                            </div>
                            <div className={`col-md-2 kt-margin-b-20-tablet-and-mobile margin-t25`}>
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input                                      
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Policy..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(e) => setSearchString(e.target.value)}
                                        >
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (<Loading/>) : (
                    filteredBusinessReports.length > 0 ? (
                        <>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                        <table className="kt-datatable__table">
                                            <thead
                                                className="kt-datatable__head"
                                                style={{ display: 'block' }}>
                                                <tr className="kt-datatable__row block-row">
                                                    {productType == 'Other Product' && (
                                                        <>
                                                            <th className="kt-datatable__cell text-clip" width="14%">
                                                                <span className="padding-l10">Policy Number</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="14%">
                                                                <span className="padding-l10">Insured Name</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>Company</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="10%">
                                                                <span>Start Date</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>Net Premium</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>Percent%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>Commission</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>TDS%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="11%">
                                                                <span>Payout</span>
                                                            </th>
                                                            <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="5%">
                                                            </th>
                                                        </>
                                                    )}
                                                    {productType == 'Motor' && (
                                                        <>
                                                            <th className="kt-datatable__cell text-clip" width="10%">
                                                                <span className="">Policy Number</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="10%">
                                                                <span className="">Insured Name</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="10%">
                                                                <span>Company</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="10%">
                                                                <span>Start Date</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>OD Premium</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>AO Premium</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>TP Premium</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>OD Percent%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>AO Percent%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>TP Percent%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>OD Commission</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>AO Commission</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>TP Commission</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>TDS%</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="8%">
                                                                <span>Payout</span>
                                                            </th>
                                                            <th className="kt-datatable__cell--center kt-datatable__cell text-clip" width="5%">
                                                            </th>
                                                        </>
                                                    )}
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div 
                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                        onScroll={helper.setStickyHeader}>
                                        <table className="kt-datatable__table table-striped">
                                            <tbody
                                                className="kt-datatable__body"
                                                style={{ display: 'block', minHeight:'40vh'}}>
                                                {filteredBusinessReports.map((p, i) => (
                                                    <tr
                                                        key={i}
                                                        className="kt-datatable__row block-row">
                                                        {productType == 'Other Product' && (
                                                            <>
                                                                <td width="14%" className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        <div className="kt-user-card-v2__pic">
                                                                            <img src={p.ProductIcon} alt="product"/>
                                                                        </div>
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip" title={p.PolicyNo}>
                                                                                {p.PolicyNo}                  
                                                                            </span>                                                               
                                                                            <span className="kt-user-card-v2__desc text-clip" title={p.ProductName}>{p.ProductName}</span>
                                                                        </div>                                                     
                                                                    </div>                                                       
                                                                </td>
                                                                <td width="14%" title={p.DisplayName} className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        <div className="kt-user-card-v2__pic">
                                                                            <div
                                                                                className={`img-small-profile kt-badge kt-badge--xl kt-badge--${
                                                                                p.Type === 'Corporate' ? 'success' : 'info'
                                                                                }`}>
                                                                                {p.DisplayName.slice(0, 1)}
                                                                            </div>
                                                                        </div>
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td width="11%" title={p.InsuranceCompanyName} className="kt-datatable__cell text-clip">
                                                                    <span>{p.InsuranceCompanyName}</span>
                                                                </td>
                                                                <td width="10%" title={moment(p.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{moment(p.FromDate).format('DD/MM/YYYY')}</span>
                                                                </td>
                                                                <td width="11%" title={p.Premium} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.Premium}</span>
                                                                </td>
                                                                <td width="11%" title={p.Percent} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.Percent}</span>
                                                                </td>
                                                                <td width="11%" title={p.Commission} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.Commission}</span>
                                                                </td>
                                                                <td width="11%" title={p.TDS} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.TDS}</span>
                                                                </td>
                                                                <td width="11%" title={calculateTotalPayout(p.Commission, p.TDS)} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{calculateTotalPayout(p.Commission, p.TDS)}</span>
                                                                </td>
                                                                <td width="5%" className="kt-datatable__cell">
                                                                    <div className='dropdown'>
                                                                        <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                                            <i className="flaticon-more-1"></i>
                                                                        </a>
                                                                        <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                                            <a className='dropdown-item' onClick={() => setEditPolicyCommission(p)}>
                                                                                <i className='la la-edit'></i>
                                                                                Edit Commission
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                        {productType == 'Motor' && (
                                                            <>
                                                                <td width="10%" className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        {/* <div className="kt-user-card-v2__pic">
                                                                            <img src={p.ProductIcon} alt="product"/>
                                                                        </div> */}
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip" title={p.PolicyNo}>
                                                                                {p.PolicyNo}                  
                                                                            </span>                                                               
                                                                            <span className="kt-user-card-v2__desc text-clip" title={p.ProductName}>{p.ProductName}</span>
                                                                        </div>                                                     
                                                                    </div>                                                       
                                                                </td>
                                                                <td width="10%" title={p.DisplayName} className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        {/* <div className="kt-user-card-v2__pic">
                                                                            <div
                                                                                className={`img-small-profile kt-badge kt-badge--xl kt-badge--${
                                                                                p.Type === 'Corporate' ? 'success' : 'info'
                                                                                }`}>
                                                                                {p.DisplayName.slice(0, 1)}
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td width="10%" title={p.InsuranceCompanyName} className="kt-datatable__cell text-clip">
                                                                    <span>{p.InsuranceCompanyName}</span>
                                                                </td>
                                                                <td width="10%" title={moment(p.FromDate).format('DD/MM/YYYY')} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{moment(p.FromDate).format('DD/MM/YYYY')}</span>
                                                                </td>
                                                                <td width="8%" title={p.ODPremium} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.ODPremium}</span>
                                                                </td>
                                                                <td width="8%" title={p.AddOnPremium} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.AddOnPremium}</span>
                                                                </td>
                                                                <td width="8%" title={p.TPPremium} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.TPPremium}</span>
                                                                </td>
                                                                <td width="8%" title={p.ODPercent} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.ODPercent}</span>
                                                                </td>
                                                                <td width="8%" title={p.AOPercent} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.AOPercent}</span>
                                                                </td>
                                                                <td width="8%" title={p.TPPercent} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.TPPercent}</span>
                                                                </td>
                                                                <td width="8%" title={p.ODCommission} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.ODCommission}</span>
                                                                </td>
                                                                <td width="8%" title={p.AOCommission} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.AOCommission}</span>
                                                                </td>
                                                                <td width="8%" title={p.TPCommission} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.TPCommission}</span>
                                                                </td>
                                                                <td width="8%" title={p.TDS} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{p.TDS}</span>
                                                                </td>
                                                                <td width="8%" title={'Payout'} className="kt-datatable__cell text-clip">
                                                                    <span className="kt-font-bold">{calculateTotalPayoutForMotor(p.ODCommission, p.AOCommission, p.TPCommission, p.TDS)}</span>
                                                                </td>
                                                                
                                                                <td width="5%" className="kt-datatable__cell">
                                                                    <div className='dropdown'>
                                                                        <a className='btn btn-sm btn-clean btn-icon btn-icon-md' data-toggle='dropdown' aria-expanded='true'>
                                                                            <i className="flaticon-more-1"></i>
                                                                        </a>
                                                                        <div className='dropdown-menu dropdown-menu-right' x-placement='top-end'>
                                                                            <a className='dropdown-item' onClick={() => setEditPolicyCommission(p)}>
                                                                                <i className='la la-edit'></i>
                                                                                Edit Commission
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded margin-b0" 
                                        style={{position: 'sticky', left: '0', bottom: '0'}}>
                                        <table className="kt-datatable__table">
                                            <tbody
                                                className="kt-datatable__body"
                                                style={{ display: 'block', minHeight: '5vh' }}>
                                                    <tr
                                                        className="kt-datatable__row block-row"> 
                                                        
                                                        <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="15%">Total Premium : </td>
                                                        <td className="kt-datatable__cell" width="10%">
                                                            <span className="kt-font-bold footer-font"><i className='la la-rupee'></i>{filteredBusinessReports.map(item => item.Premium).reduce((prev, curr) => prev + curr, 0)}</span>
                                                        </td>
                                                        {productType == 'Other Product' && (
                                                            <>
                                                                <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="17%">Total Commission : </td>
                                                                <td className="kt-datatable__cell" width="9%">
                                                                    <span className="kt-font-bold footer-font"><i className='la la-rupee'></i>{Math.ceil(filteredBusinessReports.map(f => f.Commission).reduce((prev, curr) => prev + curr, 0))}</span>
                                                                </td>
                                                            </>
                                                        )}
                                                        {productType == 'Motor' && (
                                                            <>
                                                                <td className="kt-datatable__cell kt-font-bold footer-font text-right" width="17%">Total Commission : </td>
                                                                <td className="kt-datatable__cell" width="9%">
                                                                    <span className="kt-font-bold footer-font"><i className='la la-rupee'></i>{calculateCommission()}</span>
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <table id='policy-commission-table' className='d-none'>
                                <thead className="thead-dark">
                                    {productType == 'Other Product' && (
                                        <tr>
                                            <th>Policy Number</th>
                                            <th>Product Name</th>
                                            <th>Insured Name</th>
                                            <th>Client No.</th>
                                            <th>Company</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Net Premium</th>
                                            <th>GST</th>
                                            <th>Gross Premium</th>
                                            <th>Percent</th>
                                            <th>Commission</th>
                                            <th>TDS</th>
                                            <th>Payout</th>
                                            <th>Policy Upload Date</th>
                                        </tr>
                                    )}
                                    {productType == 'Motor' && (
                                        <tr>
                                            <th>Policy Number</th>
                                            <th>Product Name</th>
                                            <th>Insured Name</th>
                                            <th>Client No.</th>
                                            <th>Company</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>GST</th>
                                            <th>Gross Premium</th>
                                            <th>OD Premium</th>
                                            <th>OD Percent</th>
                                            <th>OD Commission</th>
                                            <th>AO Premium</th>
                                            <th>AO Percent</th>
                                            <th>AO Commission</th>
                                            <th>TP Premium</th>
                                            <th>TP Percent</th>
                                            <th>TP Commission</th>
                                            <th>TDS</th>
                                            <th>Payout</th>
                                            <th>Policy Upload Date</th>
                                        </tr>
                                    )}
                                </thead>
                                <tbody>
                            
                                    {filteredBusinessReports.map((f)=>
                                        <tr key={f.ID}>
                                            {productType == 'Other Product' && (
                                                <>
                                                    <td>{f.PolicyNo}</td>
                                                    <td>{f.ProductName}</td>
                                                    <td>{f.DisplayName}</td>
                                                    <td>{f.PrimaryClientMobile}</td>
                                                    <td>{f.InsuranceCompanyName}</td>
                                                    <td>{moment(f.FromDate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(f.ToDate).format('DD/MM/YYYY')}</td>
                                                    <td>{f.Premium}</td>
                                                    <td>{f.GST}</td>
                                                    <td>{f.GrossPremium}</td>
                                                    <td>{f.Percent}%</td>
                                                    <td>{f.Commission}</td>
                                                    <td>{f.TDS}%</td>
                                                    <td>{calculateTotalPayout(f.Commission, f.TDS)}</td>
                                                    <td>{moment(f.CreatedDate).format('DD/MM/YYYY')}</td>
                                                </>
                                            )}  
                                            {productType == 'Motor' && (
                                                <>
                                                    <td>{f.PolicyNo}</td>
                                                    <td>{f.ProductName}</td>
                                                    <td>{f.DisplayName}</td>
                                                    <td>{f.PrimaryClientMobile}</td>
                                                    <td>{f.InsuranceCompanyName}</td>
                                                    <td>{moment(f.FromDate).format('DD/MM/YYYY')}</td>
                                                    <td>{moment(f.ToDate).format('DD/MM/YYYY')}</td>
                                                    <td>{f.GST}</td>
                                                    <td>{f.GrossPremium}</td>
                                                    <td>{f.ODPremium}</td>
                                                    <td>{f.ODPercent}%</td>
                                                    <td>{f.ODCommission}</td>
                                                    <td>{f.AddOnPremium}</td>
                                                    <td>{f.AOPercent}%</td>
                                                    <td>{f.AOCommission}</td>
                                                    <td>{f.TPPremium}</td>
                                                    <td>{f.TPPercent}%</td>
                                                    <td>{f.TPCommission}</td>
                                                    <td>{f.TDS}%</td>
                                                    <td>{calculateTotalPayoutForMotor(f.ODCommission, f.AOCommission, f.TPCommission, f.TDS)}</td>
                                                    <td>{moment(f.CreatedDate).format('DD/MM/YYYY')}</td>
                                                </>
                                            )}
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <NoData
                            src={ReportImg}
                            alt="Policy"
                            message="No Reports Found"
                        />
                    )
                )}
            </div>
            {editPolicyCommission && (
                <PolicyCommissionModal
                    action="e"
                    policyDetails={editPolicyCommission}
                    productName={editPolicyCommission.ProductName}
                    policyId={editPolicyCommission.ID}
                    isMotorPolicy={editPolicyCommission.ExtraFields == 'MotorPolicy' ? true : false}
                    refetchPolicies={() => fetchPolicyCommissionReports()}
                    onDismissModal={() => setEditPolicyCommission(false)}
                />
            )}
        </>
    )
}

export default PolicyReport