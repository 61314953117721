import React, { useState, useEffect } from 'react';
import $ from 'jquery';
// import 'jquery-form';
// import 'jquery-validation';
import '../../assets/scripts/bootstrap-modal';
// import '../../assets/scripts/jquery-validation.init';
const PolicyPaymentModal = (props) => {

    useEffect(() => {
        $('#add-policy-payment-modal').modal({ backdrop: 'static' });

        $('#add-policy-payment-modal').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            $(props.from).removeClass('modal-blur');
            props.onDismissModal(); 
        });
        $(props.from).addClass('modal-blur');
    }, []);
    
    const addCompany = async (e) => {
        $('#add-policy-payment-modal').modal('hide');
    }

    return (
        <div
            className="modal fade"
            id="add-policy-payment-modal"
            tabIndex="-1"
            role="dialog"
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Policy Payment</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                        ></button>
                    </div>
                    <div className="modal-body padding-0">
                        <div id="contact-modal-holder" className="kt-form kt-form--label-right padding-10" role="form">
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Payment Mode</label>
                                <div className="col-xl-6 col-lg-8">
                                    <select
                                        className="form-control kt-selectpicker"
                                        id="ddlBannerType"
                                        value={props.paymentDetails.PaymentType}
                                        onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, PaymentType: target.value}))}>                                                 
                                        <option value="0">Select Payment Mode</option>
                                        <option value="Cheque">Cheque</option>
                                        <option value="Cash">Cash</option>
                                        <option value="Online Payment">Online Payment</option>
                                        <option value="Bank Transfer">Bank Transfer</option>
                                    </select>
                                </div>
                            </div>
                            {props.paymentDetails.PaymentType == 'Cheque' && (
                                <>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Cheque Date</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <input
                                                id="ChequeDate"
                                                value={props.paymentDetails.ChequeDate || ''}
                                                // max={moment().format('YYYY-MM-DD')}
                                                name="ChequeDate"
                                                className="form-control"
                                                type="date"
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, ChequeDate: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Cheque No.</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <input
                                                id="ChequeNo"
                                                value={props.paymentDetails.ChequeNo || ''}
                                                name="ChequeNo"
                                                className="form-control"
                                                type="text"
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, ChequeNo: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Bank Name</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <input
                                                id="BankName"
                                                value={props.paymentDetails.BankName || ''}
                                                name="BankName"
                                                className="form-control"
                                                type="text"
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, BankName: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(props.paymentDetails.PaymentType == 'Cash' || props.paymentDetails.PaymentType == 'Online Payment') && (
                                <>
                                    {props.paymentDetails.PaymentType == 'Online Payment' && (
                                        <div className="form-group row">
                                            <label className="col-form-label col-lg-3">Reference No.</label>
                                            <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                                <input
                                                    id="ReferenceNo"
                                                    value={props.paymentDetails.ReferenceNo || ''}
                                                    name="ReferenceNo"
                                                    className="form-control"
                                                    type="text"
                                                    onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, ReferenceNo: target.value}))}                                                 
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Remark</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <textarea
                                                id="Remark"
                                                value={props.paymentDetails.Remark || ''}
                                                name="Remark"
                                                className="form-control"
                                                type="text"
                                                rows={4}
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, Remark: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(props.paymentDetails.PaymentType == 'Bank Transfer') && (
                                <>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Bank Name</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <input
                                                id="BankName"
                                                value={props.paymentDetails.BankName || ''}
                                                name="BankName"
                                                className="form-control"
                                                type="text"
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, BankName: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-form-label col-lg-3">Reference No.</label>
                                        <div className="kt-form__control kt-form__group--inline col-xl-6 col-lg-8">
                                            <input
                                                id="ReferenceNo"
                                                value={props.paymentDetails.ReferenceNo || ''}
                                                name="ReferenceNo"
                                                className="form-control"
                                                type="text"
                                                onChange={({ target }) => props.setPaymentDetails((prev) => ({...prev, ReferenceNo: target.value}))}                                                 
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary margin-0 margin-r5"
                            data-dismiss="modal">   
                            Close
                        </button>
                        <button
                            id="save-company"
                            onClick={addCompany}
                            data-dismiss="modal"
                            type="button"
                            className="btn btn-brand margin-0">   
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PolicyPaymentModal